<template>
    <div id="container-principal">
        <h1 class="fw-normal mt-5 text-center">Gestión de carta</h1>
        <div class="d-flex aling-items-center justify-content-center my-4" style="gap:3rem">
            <button :class="['button', selectedButton === 'platos' ? 'selected' : '']" @click="selectedButton = 'platos'">Platos</button>
            <button :class="['button', selectedButton === 'categorias' ? 'selected' : '']" @click="selectedButton = 'categorias'">Categorías</button>
        </div>
        <div class="d-flex justify-content-center mb-4">
            <div class="d-flex justify-content-between align-items-center">
                <div id="search-btn" class="w-100">
                    <i class="fa-solid fa-search"></i>
                    <input autocomplete="off" class="search-input" placeholder="Buscar" v-model="search">
                </div>
                <button class="btn btn-add d-none d-md-block px-5 ml-3" @click="selectedButton === 'platos' ? showAddDishModal() : showAddCategoryModal()">Añadir</button>
            </div>
        </div>
        <div class="d-flex justify-content-center mb-3">
            <button class="btn btn-add d-block d-md-none px-5 ml-3" @click="selectedButton === 'platos' ? showAddDishModal() : showAddCategoryModal()">Añadir</button>
        </div>
        <div v-if="selectedButton === 'platos'">
            <div v-if="filteredDishes.length > 0" class="row d-flex align-items-center justify-content-center">
                <div class="col-11 col-md-6 col-xl-4 mb-3" v-for="dish in filteredDishes" :key="dish.id">
                    <div class="card h-100">
                        <div class="w-100 d-flex flex-column flex-md-row g-0">
                            <div class="col-12 d-flex flex-column p-0 position-relative">
                                <div class="card-actions d-md-none position-absolute" style="top: 0; right: 0;">
                                    <i class="fa-solid fa-pencil mr-2 action-icon" style="color: #f47700;" @click="showEditDishModal(dish)"> </i>
                                    <i class="fa-regular fa-trash-can action-icon" style="color: #f47700;" @click="showDeleteDishModal(dish)"> </i>
                                </div>
                                <div class="d-flex align-items-center p-0 flex-wrap mt-3">
                                    <div class="col d-flex justify-content-center">
                                        <img :src="dish.photo || defaultImageUrl" class="img-fluid dish-image" alt="dish Image" />
                                    </div>
                                    <div class="col d-flex flex-column justify-content-center p-2 mt-2 text-center dish-text">
                                        <div class="p-0">
                                            <h3 class="dish-name" style="font-size: 1.5rem;">{{ dish.name }}</h3>
                                            <p class="dish-description">{{ dish.description }}</p>
                                            <div class="card-actions d-none d-md-block mt-2 d-flex align-items-center justify-content-center">
                                                <i class="fa-solid fa-pencil mr-2 action-icon" style="color: #f47700;" @click="showEditDishModal(dish)"> </i>
                                                <i class="fa-regular fa-trash-can action-icon" style="color: #f47700;" @click="showDeleteDishModal(dish)"> </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p v-else class="text-center">No hay platos disponibles.</p>
        </div>
        <div v-if="selectedButton === 'categorias'">
            <div v-if="filteredCategories.length > 0" class="row d-flex align-items-center justify-content-center">
                <div class="col-9 col-md-6 mb-3" v-for="category in filteredCategories" :key="category.id">
                    <div class="card" style="min-height: 180px">
                        <div class="d-flex flex-column flex-md-row g-0">
                            <div class="col d-flex flex-column p-0">
                                <div class="card-actions d-md-none position-absolute p-3" style="top: 0; right: 0;">
                                    <i class="fa-solid fa-pencil mr-2 action-icon" style="color: #f47700;" @click="showEditCategoryModal(category)"> </i>
                                    <i class="fa-regular fa-trash-can action-icon" style="color: #f47700;" @click="showDeleteCategoryModal(category)"> </i>
                                </div>
                                <div class="d-flex align-items-center p-0 flex-wrap mt-3">
                                    <div class="col d-flex flex-column justify-content-center p-2 mt-2">
                                        <div class="p-0">
                                            <h3 class="category-name fw-bold" style="font-size: 1.7rem; text-align: center;">{{ category.position }}</h3>
                                            <h4 class="category-description fw-semibold" style="text-align: center;">{{ category.name }}</h4>
                                            <div class="card-actions d-none d-md-block mt-2 d-flex align-items-center justify-content-center">
                                                <i class="fa-solid fa-pencil mr-2 action-icon" style="color: #f47700;" @click="showEditCategoryModal(category)"> </i>
                                                <i class="fa-regular fa-trash-can action-icon" style="color: #f47700;" @click="showDeleteCategoryModal(category)"> </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p v-else class="text-center">No hay categorías disponibles.</p>
        </div>

        <EditDishModalComponent
            v-if="selectedButton === 'platos'"
            :show-modal="showEditDish"
            :dish="selectedDish"
            :categorias="categories"
            :platos="dishes.filter(dish => dish.steps_list.length==0)"
            @close-modal="closeEditDishModal"
            @update-dish="updateDish"
        />
        <DeleteDishModalComponent
            v-if="selectedButton === 'platos'"
            :show-modal="showDeleteDish"
            :dish="selectedDish"
            @close-modal="closeDeleteDishModal"
            @confirm-delete="confirmDeleteDish"
        />
        <AddDishModalComponent
            v-if="selectedButton === 'platos'"
            :show-modal="showAddDish"
            :categorias="categories"
            :platos="dishes.filter(dish => dish.steps_list.length==0)"
            @close-modal="closeAddDishModal"
            @confirm-add="addDish"
        />

        <EditCategoryModalComponent
            v-if="selectedButton === 'categorias'"
            :show-modal="showEditCategory"
            :category="selectedCategory"
            @close-modal="closeEditCategoryModal"
            @update-category="updateCategory"
        />
        <DeleteCategoryModalComponent
            v-if="selectedButton === 'categorias'"
            :show-modal="showDeleteCategory"
            :category="selectedCategory"
            @close-modal="closeDeleteCategoryModal"
            @confirm-delete="confirmDeleteCategory"
        />
        <AddCategoryModalComponent
            v-if="selectedButton === 'categorias'"
            :show-modal="showAddCategory"
            @close-modal="closeAddCategoryModal"
            @confirm-add="addCategory"
        />
        <SpinnerComponent id="spinner"></SpinnerComponent>
    </div>
</template>


<script>
    import EditDishModalComponent from '../components/EditDishModalComponent.vue';
    import DeleteDishModalComponent from '../components/DeleteDishModalComponent.vue';
    import AddDishModalComponent from '../components/AddDishModalComponent.vue';

    import EditCategoryModalComponent from '../components/EditCategoryModalComponent.vue';
    import DeleteCategoryModalComponent from '../components/DeleteCategoryModalComponent.vue';
    import AddCategoryModalComponent from '../components/AddCategoryModalComponent.vue';

    import { mapState } from 'vuex';
    import axios from 'axios';
    import SpinnerComponent from '../components/SpinnerComponent.vue'
    import { v4 as uuidv4 } from 'uuid';

    export default {
        components: {
            EditDishModalComponent,
            DeleteDishModalComponent,
            AddDishModalComponent,
            EditCategoryModalComponent,
            DeleteCategoryModalComponent,
            AddCategoryModalComponent,
            SpinnerComponent
        },
        data() {
            return {
                dishes: [],
                categories: [],
                selectedDish: null,showEditDish: false,showDeleteDish: false,showAddDish: false,
                selectedCategory: null,showEditCategory: false,showDeleteCategory: false,showAddCategory: false,
                defaultImageUrl: require('@/assets/no_plate.png'),
                selectedButton: 'platos',
                search: '',
                esPrimeraVez: true
            };
        },
        computed: {
            filteredDishes() {
                return this.dishes.filter(dish =>
                    dish.name.toLowerCase().includes(this.search.toLowerCase())
                );
            },
            filteredCategories() {
                return this.categories.filter(category =>
                    category.name.toLowerCase().includes(this.search.toLowerCase())
                );
            },
            ...mapState(['partner_token'])
        },
        methods: {
            showEditDishModal(dish) {
                this.selectedDish = { ...dish };
                this.showEditDish = true;
            },
            closeEditDishModal() {
                this.showEditDish = false;
                this.selectedDish = null;
            },
            showDeleteDishModal(dish) {
                this.selectedDish = { ...dish };
                this.showDeleteDish = true;
            },
            closeDeleteDishModal() {
                this.showDeleteDish = false;
                this.selectedDish = null;
            },
            showAddDishModal() {
                this.showAddDish = true;
            },
            closeAddDishModal() {
                this.showAddDish = false;
            },
            async addDish(newDish) {
                console.log(newDish)
                let plate = {
                    id: String(uuidv4()),
                    name: newDish.name,
                    description: newDish.description,
                    photo: newDish.photo,
                    price: newDish.price,
                    IVA: newDish.IVA,
                    category: newDish.category,
                    available: newDish.available,
                    steps_list: newDish.steps_list.map(step => ({
                        ...step,
                        plates: step.plates.map(plate => ({
                            id: plate.id,
                            name: plate.name,
                            description: plate.description,
                            price: plate.price,
                            IVA: plate.IVA
                        }))
                    }))
                }
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/plateCreate';
                try {
                    const response = await axios.post(url, plate, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    this.getPlates()
                } catch (error) {
                    console.error('Error fetching shopping cart:', error);
                }
                this.closeAddDishModal();
            },
            async updateDish(updatedDish) {
                let steps_list = []
                if (updatedDish?.steps_list && Array.isArray(updatedDish.steps_list)) {
                    steps_list = updatedDish.steps_list.map(step => ({
                        ...step,
                        plates: step.plates.map(plate => ({
                            id: plate.id,
                            name: plate.name,
                            description: plate.description,
                            price: plate.price,
                            IVA: plate.IVA
                        }))
                    }))
                }
                const serializedDish = {
                    ...updatedDish,
                    category: updatedDish.category.id,
                    steps_list: steps_list
                };
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/plateUpdate';
                try {
                    const response = await axios.put(url, serializedDish, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    await this.getPlates()
                    let plate_updated = this.dishes.find(plate => plate.id === updatedDish.id)
                    if (plate_updated) {
                        if(plate_updated.photo){
                            plate_updated.photo = plate_updated.photo+"?v="+Date.now();
                        }
                    }
                } catch (error) {
                    console.error('Error updating plate:', error);
                }
                this.closeEditDishModal()
            },
            async confirmDeleteDish(id) {
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/plateDelete?plate_id='+id;
                try {
                    const response = await axios.delete(url, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    this.getPlates()
                } catch (error) {
                    console.error('Error deleting plate:', error);
                }
                this.closeDeleteDishModal();
            },
            showEditCategoryModal(category) {
                this.selectedCategory = { ...category };
                this.showEditCategory = true;
            },
            closeEditCategoryModal() {
                this.showEditCategory = false;
                this.selectedCategory = null;
            },
            showDeleteCategoryModal(category) {
                this.selectedCategory = { ...category };
                this.showDeleteCategory = true;
            },
            closeDeleteCategoryModal() {
                this.showDeleteCategory = false;
                this.selectedCategory = null;
            },
            showAddCategoryModal() {
                this.showAddCategory = true;
            },
            closeAddCategoryModal() {
                this.showAddCategory = false;
            },
            async addCategory(newCategory) {
                let category = {
                    id: String(uuidv4()),
                    name: newCategory.name,
                    position: newCategory.position,
                    available: newCategory.available
                }
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/categoryCreate';
                try {
                    const response = await axios.post(url, category, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    await this.getCategories();
                    await this.getPlates();
                } catch (error) {
                    console.error('Error fetching category:', error);
                }
                this.closeAddCategoryModal();
            },
            async updateCategory(updatedCategory) {
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/categoryUpdate';
                try {
                    const response = await axios.put(url, updatedCategory, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    await this.getCategories();
                    await this.getPlates(); 
                } catch (error) {
                    console.error('Error updating category:', error);
                }
                this.closeEditCategoryModal();
            },
            async confirmDeleteCategory(id) {
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/categoryDelete?category_id='+id;
                try {
                    const response = await axios.delete(url, {
                    headers: {
                        'Authorization': `Bearer ${this.partner_token}`
                    }
                    });
                    console.log(response.data);
                    this.getCategories();
                    await this.getPlates();
                } catch (error) {
                    console.error('Error deleting category:', error);
                }
                this.closeDeleteCategoryModal();
            },
            async getPlates(){
                if (document.getElementById("spinner")){
                    document.getElementById("spinner").classList.remove("d-none")
                }
                const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/GetPlates';
                try {
                    const response = await axios.get(url, {
                        headers: {
                        'Authorization': `Bearer ${token}`
                        }
                    });

                    // Maneja la respuesta
                    console.log(response.data);
                    this.dishes = response.data
                    this.dishes.sort((a, b) => {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1;  // a va antes que b
                        }
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;   // a va después que b
                    }
                        return 0;     // a y b son iguales
                    });
                    if (document.getElementById("spinner")){
                        document.getElementById("spinner").classList.add("d-none")
                        this.esPrimeraVez = false
                    }
                } catch (error) {
                    // Maneja el error
                    this.dishes = []
                    if (document.getElementById("spinner")){
                        document.getElementById("spinner").classList.add("d-none")
                    }
                    console.error('Error fetching tables:', error);
                }
            },
            async getCategories(){
                if (document.getElementById("spinner")){
                    document.getElementById("spinner").classList.remove("d-none")
                }
                const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
                const url = process.env.VUE_APP_API_URL+'/v1/api/app/GetCategories';
                try {
                    const response = await axios.get(url, {
                        headers: {
                        'Authorization': `Bearer ${token}`
                        }
                    });

                    // Maneja la respuesta
                    console.log(response.data);
                    this.categories = response.data.sort((a, b) => a.position - b.position);
                    if (document.getElementById("spinner") && !this.esPrimeraVez){
                        document.getElementById("spinner").classList.add("d-none")
                    }
                } catch (error) {
                    // Maneja el error
                    this.categories = []
                    if (document.getElementById("spinner")){
                        document.getElementById("spinner").classList.add("d-none")
                    }
                    console.error('Error fetching tables:', error);
                }
            }
        },
        created() {
            this.getPlates()
            this.getCategories()
        },
    };
</script>

<style scoped>
    #search-btn {
        display: flex;
        height: 35px;
        background: #fff;
        padding: 20px;
        font-size: 16px;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border: 2px solid #dbdbdb;
        border-radius: 13px;
        color: #0A2533;
    }

    .button {
        background-color: #ececec;
        border: none;
        border-radius: 1.5rem;
        padding: 20px 30px;
        font-size: 16px;
        cursor: pointer;
        color: #1a2b39;
        font-family: 'Arial', sans-serif;
        transition: border-color 0.3s ease;
    }

    .button:focus{
        border: solid 1px #f47700 ;
    }

    .card {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 16px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    .card-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
    }

    .action-icon {
        font-size: 2rem;
        color: #f47700;
        cursor: pointer;
    }

    .btn.btn-add {
        background-color: #f47700;
        color: white;
        font-size: 1.25rem;
        border-radius: 8px;
        height: 40px;
        border: none;
        cursor: pointer;
    }

    .dish-name{
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        height: 4rem;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 1.5;
        max-height: calc(1.5em * 2);
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-word;
    }

    .dish-description {
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        height: 4rem;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 1.5;
        max-height: calc(1.5em * 2);
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-word;
    }


    .dish-image {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
    }

    .dish-text {
        min-height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .card {
        min-height: 300px;
    }

    .category-description {
        text-align: center;
        overflow: hidden;
        height: 2rem;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 1.5;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: normal;
    }

    @media (min-width: 768px) {
        .card-actions {
            position: static;
            text-align: center;
        }
        .action-icon {
            margin-inline: 1rem;
        }
        #container-principal{
            margin-left:22%; 
            width: 77%;
        }
    }

    @media (max-width: 576px) {
        .action-icon {
            font-size: 1.3rem;
        }
        #container-principal{
            width: 90%;
            margin: auto;
        }
    }

    @media (max-width: 877px) and (min-width: 768px) {
        .action-icon {
            font-size: 1.4rem;
        }
    }
</style>
