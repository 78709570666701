<template>
  <div v-if="showModal" class="modal fade show d-block z-3 d-flex align-items-center justify-content-center" tabindex="-1">
    <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
    <div class="modal-dialog z-2 m-4">
      <div class="modal-content p-2 custom-scroll">
        <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
          <h3 class="modal-title">Editar plato</h3>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitEdit">
            <div class="row">
              <div class="col-md-6 mb-5">
                <div class="form-group">
                  <b for="dish-name">Nombre:</b><br />
                  <input type="text" id="name" v-model="localDish.name" class="form-control shadow rounded-3" maxlength="100">
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <div class="form-group">
                  <b for="dish-category">Categoría:</b><br />
                  <select id="dish-category" v-model="localDish.category.id" class="form-control shadow rounded-3">
                      <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12 mb-4">
                  <div class="form-group">
                    <b for="dish-description">Descripción:</b><br />
                    <textarea id="dish-description" v-model="localDish.description" class="form-control shadow rounded-3" maxlength="300"></textarea>
                  </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <b for="dish-price">Precio (€):</b><br />
                  <input type="number" step="0.01" v-model="localDish.price" class="form-control shadow rounded-3"/>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                  <div class="form-group">
                    <b for="dish-iva">IVA (%):</b><br />
                    <input type="number" step="1" v-model="localDish.IVA" class="form-control shadow rounded-3" min="0" max="30"/>
                  </div>
              </div>
              <div class="col-md-12 mb-4 row m-0 ">
                <div class="d-flex align-items-center fs-5 p-0">
                  <h6 class="d-block me-2 mt-1 fw-bold">Menu</h6>
                  <div class="form-check form-switch">
                    <input class="form-check-input custom-slider" type="checkbox" id="advanceMode" v-model="hasSteps"/>
                    <label class="form-check-label" for="advanceMode"></label>
                  </div>
                </div>
                <div v-if="hasSteps">
                  <div v-for="step in localDish.steps_list" :key="step.id" class="p-3 mt-2 border border-2 rounded-2 position-relative">
                    <i class="fa-solid fa-trash position-absolute fs-5 z-3 text-danger" style="right: 10px; top: 10px" @click="removeStep(step)"></i>
                      <div class="col-12">
                        <div class="form-floating mb-1">
                          <input type="text" class="form-control" id="floatingInput" v-model="step.name" maxlength="100" />
                          <label for="floatingInput">Nombre</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mt-1">
                          <label>Platos seleccionables:</label>
                          <v-col class="w-100 pb-0">
                            <v-combobox :items="plates_names" label="Selecciona las platos" chips multiple v-model="plates_selected_names[step.id]" ></v-combobox>
                          </v-col>
                        </div>
                      </div>
                  </div>
                  <span  @click.prevent="addStep" style="cursor: pointer;" class="text-warning fw-bold">
                    Añadir paso <i class="fa-solid fa-plus mt-3  fs-5 ms-1" ></i>
                  </span>
                </div>
              </div>
              <div class="col-md-12 mb-2 row m-0">
                <div class="form-check form-switch mx-auto d-flex justify-content-center">
                  <input class="form-check-input fs-5 me-3 " v-model="localDish.available" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  <h6 class="form-check-label mt-1 fw-bold" for="flexSwitchCheckDefault">Disponible</h6>
                </div>
              </div>
              <div class="col-md-12 mb-5">
                <div class="form-group">
                  <b for="dish-image">Imagen:</b><br />
                  <input type="file" id="dish-image" @change="handleFileUpload" class="form-control shadow rounded-3" />
                  <div class="row d-flex justify-content-center">
                    <img v-if="imagePreview" :src="imagePreview" alt="Imagen del plato" class="img-fluid mt-3" style="max-width: 250px; " />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <span id="span-error" class="text-danger text-center pb-3"></span>
            </div>
            <div class="modal-footer align-items-center justify-content-center border-none">
              <button type="submit" class="btn btn-add text-white px-4 rounded-3" style="background-color: #f47700">Actualizar</button>
              <button type="button" @click="closeModal" class="btn btn-danger px-4 rounded-3">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import defaultImage from '@/assets/no_plate.png';
  import { v4 as uuidv4 } from 'uuid';

  export default {
    props: {
      showModal: Boolean,
      dish: {
        type: Object,
        default: () => ({})
      },
      categorias: {
        type: [],
        default: () => ({})
      },
      platos: {
        type: [],
        default: () => ({})
      },
    },
    data() {
      return {
        localDish: JSON.parse(JSON.stringify(this.dish)),
        imagePreview: this.dish?.photo || defaultImage,
        categories: { ...this.categories },
        hasSteps: this.localDish && this.localDish.steps_list && this.localDish.steps_list.length > 0,
        plates: [],
        plates_selected_names: {},
      };
    },
    computed: {
      plates_names() {
        if (this.plates) {
          // Convierte el objeto en un array de valores y luego aplica map
          return Object.values(this.plates).map(plate => plate.name);
        }
        console.log("Plates are undefined or null");
        return [];
      },
    },
    methods: {
      removeStep(step) {
        this.localDish.steps_list = this.localDish.steps_list.filter(s => s !== step);
      },
      closeModal() {
        this.$emit('close-modal');
      },
      addStep(){
        this.localDish.steps_list.push({
          id: String(uuidv4()),
          name:"",
          plates:[],
        })
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const targetWidth = 300;
                const targetHeight = 300;

                canvas.width = targetWidth;
                canvas.height = targetHeight;

                ctx.fillStyle = "white";
                ctx.fillRect(0, 0, targetWidth, targetHeight);

                let scaleFactor = Math.min(targetWidth / img.width, targetHeight / img.height);
                let newWidth = img.width * scaleFactor;
                let newHeight = img.height * scaleFactor;

                let xOffset = (targetWidth - newWidth) / 2;
                let yOffset = (targetHeight - newHeight) / 2;

                ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);

                this.imagePreview = canvas.toDataURL("image/jpeg", 0.9);
                this.localDish.photo = this.imagePreview;
              };
        };
        reader.readAsDataURL(file);
      },
      submitEdit() {
        if (this.localDish.name == "" || this.localDish.description == "") {
          document.getElementById("span-error").textContent = "Debes rellenar todos los campos para continuar"
          return;
        }
        //console.log(this.localDish.steps_list)
        if (this.localDish?.steps_list && Array.isArray(this.localDish.steps_list)) {
          this.localDish.steps_list.forEach(step=>{
            //console.log(this.plates_selected_names[step.id])
            step.plates = []
            //console.log(this.plates)
            this.plates_selected_names[step.id].forEach(plate_name=>{
              let plate = Object.values(this.plates).find(plate => plate.name === plate_name);
              step.plates.push(plate)
            })
          })
        }

        let allPlatesExist = true;

        this.localDish.steps_list.forEach(step => {
            step.plates = [];
            
            if (this.plates_selected_names[step.id]) {
                this.plates_selected_names[step.id].forEach(plate_name => {
                    let plate = Object.values(this.plates).find(plate => plate.name === plate_name);
                    if (plate) {
                        step.plates.push(plate);
                    } else {
                        allPlatesExist = false;
                    }
                });
            }
        });

        if (!allPlatesExist) {
            document.getElementById("span-error").textContent = "Algunos platos en los pasos no existen. Por favor, verifica los pasos.";
            return;
        }

        //console.log(this.localDish)
        this.$emit('update-dish', this.localDish);
      },
    },
    watch: {
      dish(localDish) {
        this.localDish = { ...localDish };
        this.imagePreview = localDish?.photo || defaultImage;
        this.hasSteps = this.localDish && Array.isArray(this.localDish.steps_list) && this.localDish.steps_list.length > 0;
        console.log(this.localDish)
        if (!this.localDish.category){
          this.localDish.category = {}
        }
        // Verifica que `steps_list` existe y es un array antes de usar `forEach`
        if (this.localDish?.steps_list && Array.isArray(this.localDish.steps_list)) {
          this.localDish.steps_list.forEach(step => {
            this.plates_selected_names[step.id] = Object.values(step.plates).map(plate => plate.name);
          });
        }
      },
      categorias: {
        handler(newCategories) {
            this.categories = [...newCategories];
        },
        deep: true,
        immediate: true
      },
      platos(platos){
        this.plates = { ...platos };
      }
    },
  };
</script>
  
<style scoped>
  .modal { 
    background-color: rgba(139, 139, 139, 0.5); 
  }

  .modal-dialog {
    height: 90vh; 
    min-width: 60%;
  }

  .modal-content {
    overflow-y: auto;
    max-height: 90vh;
  }

  @media (min-width: 767px) {
    .modal-dialog {
      position: absolute;
      width: 80%;
      left: 28%;
      min-height: 90vh; 
      min-width: 60%; 
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }
  
  .custom-scroll {
    padding-top: 10px;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 7px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }
</style>
  