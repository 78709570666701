<template>
  <div class="app">
    <h1 class="text-center mt-4 fw-normal mb-3">Carrito</h1>
    <div class="px-4" id="order-info">
      <span class="fw-semibold"> {{ totalPlates }} <span v-if="max_plates != null">/ {{ max_plates }}</span> platos</span>
      <span id="total-price">Total: {{ totalPrice.toFixed(2) }}€</span>
    </div>
    <div v-if="plates.length > 0" class="pb-3">
      <div class="search-btn mx-4 mt-3">
        <i class="fa-solid fa-search "></i>
        <input autocomplete="off" class="search-input" placeholder="Buscar" v-model="search">
      </div>

      <div class="mx-0 m-0 px-3 mt-4 mb-5" id="div-platos"  v-if="this.FilterPlates().length > 0">
        <div class="plate shadow rounded-4 pb-2 mb-3 position-relative" v-for="(plate, index) in this.FilterPlates()" :key="index">
          <i class="fa-solid fa-pen position-absolute fs-5" style="right: 10px; top: 5px; color:#ff7300" @click="expandPlate(plate)"></i>
          <img :src="plate.photo ? plate.photo : noPhoto" class="card-img-top rounded-4" :id="`${plate.plate_id}_img`" alt="...">
          <p class="mt-2 text-center mb-1">{{ plate.name }}</p>
          <div class="row d-flex justify-content-between">
            <span class="ms-3 mt-1 w-auto price fw-semibold px-0" >{{ plate.price }}€</span>
            <div class="w-auto ps-0 me-2 mt-1">
              <i class="fa-regular fa-trash-can botonCantidad py-1 me-2 px-1" v-if="plate.amount && plate.amount == 1" @click="OpenDelete(plate.id)"></i>
              <i class="fa-solid fa-minus botonCantidad py-1 me-2 px-1" @click="ChangeAmount(plate.plate_id, -1)" v-else></i>
              <span style="color: #19344F" class="fw-bold" v-if="plate.steps.length==0">{{plate.amount}}</span>
              <i class="fa-solid fa-plus botonCantidad py-1 ms-2 px-1" v-if="plate.steps.length==0" @click="ChangeAmount(plate.plate_id, 1)"></i>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center ">
          <p @click="FinalizarPedido()" id="finalize-order-button" class="px-5 shadow">Finalizar Pedido</p>
        </div>
      </div>
      <div v-else class="col-9 mx-auto row m-0 mt-5" style="height: 100%;">
        <img src="./../assets/not_plates_founds.png" class="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
        <p class="text-center fs-4 fw-semibold mt-4">No hemos encontrado platos con esta búsqueda!</p>
      </div>
    </div>
    <div v-else class="h-75 d-flex align-items-center justify-content-center">
      <div class="row m-0">
        <img class="mx-auto col-xl-4 col-lg-5 col-md-7 col-sm-9" src="../../src/assets/empty-cart.png"/>
        <h2 class="text-center">Tu carrito está vacio</h2>
      </div>
    </div>

    <!-- POPUP CONFIRMACIÓN PARA ORDENADOR (ABAJO DELECHA) -->
    <div v-if="showPlateMaxError" class="position-fixed end-0 bg-white border shadow-lg p-3 rounded mb-3 mr-5 d-none d-md-flex align-items-center" style="z-index: 1000; bottom: 13%;">
      <h6 class="mt-2"><i class="fa-solid fa-circle-xmark text-danger"></i> Has alzanzado el máximo de platos por mesa</h6>
    </div>

    <!-- POPUP CONFIRMACIÓN PARA MOVIL (ALIBA CENTLO) -->
    <div v-if="showPlateMaxError" class="position-fixed top-0 start-0 w-100 h-100 d-md-none d-flex align-items-center justify-content-center" style="background-color: rgba(139, 139, 139, 0.5); z-index: 1000;">
      <div class="bg-white border shadow-lg p-3 rounded w-100 mx-3">
        <h6 class="mt-2 text-center"><i class="fa-solid fa-circle-xmark text-danger fs-5"></i><br/>Has alzanzado el máximo de platos por mesa</h6>
      </div>
    </div>

    <!-- POPUP CONFIRMACIÓN PARA ORDENADOR (ABAJO DELECHA) -->
    <div v-if="showDeliveryError" class="position-fixed end-0 bg-white border shadow-lg p-3 rounded mb-3 mr-5 d-none d-md-flex align-items-center" style="z-index: 1000; bottom: 13%;">
      <h6 class="mt-2"><i class="fa-solid fa-circle-xmark text-danger"></i>Tu pedido ya ha sido aceptado y no puedes modificarlo</h6>
    </div>

    <!-- POPUP CONFIRMACIÓN PARA MOVIL (ALIBA CENTLO) -->
    <div v-if="showDeliveryError" class="position-fixed top-0 start-0 w-100 h-100 d-md-none d-flex align-items-center justify-content-center" style="background-color: rgba(139, 139, 139, 0.5); z-index: 1000;">
      <div class="bg-white border shadow-lg p-3 rounded w-100 mx-3">
        <h6 class="mt-2 text-center"><i class="fa-solid fa-circle-xmark text-danger fs-5"></i><br/>Tu pedido ya ha sido aceptado y no puedes modificarlo</h6>
      </div>
    </div>

    <!-- POPUP PARA PEDIR DATOS PERSONALES (MODO DELIVERY) -->
    <div v-if="is_delivery" class="modal d-flex align-items-center justify-content-center p-3" tabindex="-1" style="background: rgba(0, 0, 0, 0.5); max-height: 90%;">
      <div class="bg-none position-absolute top-0 start-0 w-100 h-100" @click="closeDeliveryPopUp"></div>
      <div class="modal-dialog modal-dialog-centered z-2" style="overflow-y: auto;">
        <div class="modal-content custom-scroll">
          <div class="modal-header">
            <h5 class="modal-title w-100 text-center">
              <i class="fa-solid fa-truck"></i> Datos de envío
            </h5>
            <button type="button" class="btn-close position-absolute end-0 mr-3" @click="closeDeliveryPopUp" aria-label="Close"></button>
          </div>
          <form @submit.prevent="submitPersonalInfo">
            <div class="modal-body">
              <div class="input-group mb-4">
                <span class="input-group-text"><i class="fa-solid fa-user"></i></span>
                <div class="form-floating flex-grow-1">
                  <input type="text" id="name" v-model="userInfo.name" class="form-control" placeholder="Nombre"/>
                  <label for="name">Nombre: <span class="text-danger">*</span></label>
                </div>
              </div>
              <div class="input-group mb-4">
                <span class="input-group-text"><i class="fa-solid fa-phone"></i></span>
                <div class="form-floating flex-grow-1">
                  <input type="text" id="phone" v-model="userInfo.phone" class="form-control" placeholder="Telefono"/>
                  <label for="phone">Teléfono:<span class="text-danger">*</span></label>
                </div>
              </div>
              <div class="input-group">
                <span class="input-group-text"><i class="fa-solid fa-envelope"></i></span>
                <div class="form-floating flex-grow-1">
                  <input type="email" id="email" v-model="userInfo.email" class="form-control" placeholder="Email"/>
                  <label for="email">Email:</label>
                </div>
              </div>
              <span class="text-sm ms-2" style="font-size: 11px; color: #ff7300; margin-top:4px">(Lo usamos para informarte del estado de tu pedido)</span>
              <div class="mt-3">
                <address-browser-component @getDataBrowser="getDataBrowser" :address="userInfo.address" :longitud="userInfo.longitude" :latitud="userInfo.latitude"></address-browser-component>
              </div>
              <div class="d-flex gap-3 mb-2">
                <div class="input-group flex-grow-1">
                  <span class="input-group-text"><i class="fa-solid fa-building"></i></span>
                  <div class="form-floating flex-grow-1">
                    <input type="text" id="piso" v-model="userInfo.piso" class="form-control" placeholder="Piso" />
                    <label for="piso">Piso: <span class="text-danger">*</span></label>
                  </div>
                </div>
                <div class="input-group flex-grow-1">
                  <span class="input-group-text"><i class="fa-solid fa-door-closed"></i></span>
                  <div class="form-floating flex-grow-1">
                    <input type="text" id="cp" v-model="userInfo.codPostal" class="form-control" placeholder="Cód.Postal" />
                    <label for="cp">Cód. postal: <span class="text-danger">*</span></label>
                  </div>
                </div>
              </div>
              <div class="d-flex my-3 justify-content-center align-items-center">
                <label class="me-3">Efectivo</label>
                <v-switch
                  v-model="userInfo.payWithCard"
                  color="orange"
                  hide-details
                ></v-switch>
                <label class="ms-3" >Tarjeta</label>
              </div>
              <div class="input-group mt-2">
                <span class="input-group-text"><i class="fa-solid fa-comment-dots"></i></span>
                <div class="form-floating flex-grow-1">
                  <textarea id="additionalInfo" v-model="userInfo.additionalInfo" class="form-control" placeholder="Comentarios sobre la dirección" style="height: 100px;"></textarea>
                  <label for="additionalInfo">Información adicional:</label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
              <button type="submit" class="btn" style="background-color: #ff7300;"><i class="fa-solid fa-paper-plane text-white"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- POPUP PARA PEDIR DATOS PERSONALES (MODO TAKE AWAY) -->
    <div v-if="is_take_away" class="modal d-flex align-items-center justify-content-center p-3" tabindex="-1" style="background: rgba(0, 0, 0, 0.5); max-height: 90%;">
      <div class="bg-none position-absolute top-0 start-0 w-100 h-100" @click="closeDeliveryPopUp"></div>
      <div class="modal-dialog modal-dialog-centered z-2" style="overflow-y: auto;">
        <div class="modal-content custom-scroll">
          <div class="modal-header">
            <h5 class="modal-title w-100 text-center">
              <i class="fa-solid fa-basket-shopping"></i> Datos del pedido
            </h5>
            <button type="button" class="btn-close position-absolute end-0 mr-3" @click="closeDeliveryPopUp" aria-label="Close"></button>
          </div>
          <form @submit.prevent="submitPersonalInfoTakeAway">
            <div class="modal-body">
              <div class="input-group mb-4">
                <span class="input-group-text"><i class="fa-solid fa-user"></i></span>
                <div class="form-floating flex-grow-1">
                  <input type="text" id="name" v-model="userInfo.name" class="form-control" placeholder="Nombre"/>
                  <label for="name">Nombre: <span class="text-danger">*</span></label>
                </div>
              </div>
              <div class="input-group mb-4">
                <span class="input-group-text"><i class="fa-solid fa-phone"></i></span>
                <div class="form-floating flex-grow-1">
                  <input type="text" id="phone" v-model="userInfo.phone" class="form-control" placeholder="Telefono"/>
                  <label for="phone">Teléfono:<span class="text-danger">*</span></label>
                </div>
              </div>
              <div class="input-group">
                <span class="input-group-text"><i class="fa-solid fa-envelope"></i></span>
                <div class="form-floating flex-grow-1">
                  <input type="email" id="email" v-model="userInfo.email" class="form-control" placeholder="Email"/>
                  <label for="email">Email:</label>
                </div>
              </div>
              <span class="text-sm ms-2" style="font-size: 11px; color: #ff7300; margin-top:4px">(Lo usamos para informarte del estado de tu pedido)</span>
              
              <div class="d-flex my-3 justify-content-center align-items-center">
                <label class="me-3">Efectivo</label>
                <v-switch
                  v-model="userInfo.payWithCard"
                  color="orange"
                  hide-details
                ></v-switch>
                <label class="ms-3" >Tarjeta</label>
              </div>
              <div class="input-group mt-2">
                <span class="input-group-text"><i class="fa-solid fa-comment-dots"></i></span>
                <div class="form-floating flex-grow-1">
                  <textarea id="additionalInfo" v-model="userInfo.additionalInfo" class="form-control" placeholder="Comentarios sobre la dirección" style="height: 100px;"></textarea>
                  <label for="additionalInfo">Información adicional:</label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
              <button type="submit" class="btn" style="background-color: #ff7300;"><i class="fa-solid fa-paper-plane text-white"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <PlateExpanded ref="expanded" @ChangeAmount="ChangeAmount"></PlateExpanded>
    <DeleteProductConfirm
      :visible="isDeleteModalVisible"
      :plate="plateToDelete"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
    ></DeleteProductConfirm>
    <MenuSteps ref="menu_expanded" @fetchShoppingCart="fetchShoppingCart"></MenuSteps>
    <SpinnerComponent id="spinner"></SpinnerComponent>
  </div>
</template>

<script>
import DeleteProductConfirm from '@/components/DeleteProductConfirm.vue'
import PlateExpanded from '../components/PlateExpanded.vue'
import axios from 'axios';
import { mapState } from 'vuex';
import router from '@/router';
import SpinnerComponent from '../components/SpinnerComponent.vue'
import noPhoto from './../assets/no_plate.png';
import MenuSteps from '../components/MenuSteps.vue'
import AddressBrowserComponent from '@/components/AddressBrowserComponent.vue';

export default {
  name: 'AppCartComponent',
  data() {
    return {
      plates: [],
      isDeleteModalVisible: false,
      plateToDelete: null,
      pollingInterval: null,
      search: "",
      first_charge:true,
      showPlateMaxError: false,
      showDeliveryError: false,
      noPhoto,
      is_delivery:false,
      is_take_away:false,
      userInfo: {
        name: null,
        address: null,
        email: null,
        additionalInfo: null,
        piso: null,
        codPostal: null,
        latitude: null,
        longitude: null,
        payWithCard: null,
      },
      errorMessage: '',
      phone_regex: /^\+?(\d{1,3})[-.\s]?(\d{1,4})[-.\s]?(\d{3})[-.\s]?(\d{3,4})$/,
      email_regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    };
  },
  props: {
    max_plates: {
      type: Number,
    }
  },
  computed: {
    totalPrice() {
      return this.plates.reduce((total, plate) => total + plate.price * plate.amount, 0);
    },
    totalPlates() {
      return this.plates.reduce((total, plate) => total + plate.amount, 0);
    },
    ...mapState(['token']),
  },
  components: {
    DeleteProductConfirm,
    SpinnerComponent,
    PlateExpanded,
    MenuSteps,
    AddressBrowserComponent
  },
  methods: {
    async FinalizarPedido(){
      console.log(this.token)
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/create_order';
      document.getElementById("spinner").classList.remove("d-none")
      try {
        const response = await axios.post(url, {}, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        console.log(response.data);
        this.$emit('ReviseSession');
        router.push({ name: "MisPedidos" });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log("es delivery, recibo 403, abro popup")
          if(error.response.data.error == "delivery"){
            this.is_delivery = true;
          }else{
            this.is_take_away = true;
          }
        }else if (error.response && error.response.status === 409) {
          this.showDeliveryError = true;
          setTimeout(() => {
            this.showDeliveryError = false;
          }, 2000);
        }else {
          console.error('Error fetching shopping cart:', error);
        }
      } finally {
        document.getElementById("spinner").classList.add("d-none");
      }
    },
    FilterPlates(){
      let plates_filtered = this.plates
      if (this.search != ""){
        plates_filtered = plates_filtered.filter(plate => plate.name.toString().toLowerCase().includes(this.search.toString().toLowerCase()))
      }
      plates_filtered = plates_filtered.sort((a, b) => 
        a.name.toString().localeCompare(b.name.toString())
      );
      return plates_filtered
    },
    async fetchShoppingCart() {
      const token = this.token; // Suponiendo que tienes el token almacenado en Vuex
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/ShoppingCart';
      try {
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        let plates = response.data.shopping_carts
        let mode = response.data.mode

        let plates_structure = []
        plates.forEach(shopping_cart =>{
            let plate_structure = {}
            plate_structure.id = shopping_cart.id
            plate_structure.plate_id = shopping_cart.plate.id
            plate_structure.name = shopping_cart.plate.name
            plate_structure.description = shopping_cart.plate.description
            plate_structure.category_id=shopping_cart.plate.category.id
            plate_structure.photo = shopping_cart.plate.photo
            plate_structure.price = shopping_cart.plate.price
            plate_structure.amount = shopping_cart.amount
            plate_structure.comment = shopping_cart.comment
            plate_structure.steps = shopping_cart.plate.steps

            console.log(plate_structure)
            let i = 0
            plate_structure.steps.forEach(step=>{
              if(shopping_cart.steps_selected.length > i){
                step.plate_selected = shopping_cart.steps_selected[i].plate
              }
              i++
            })
            
            if (mode && !mode.excluded_categories.some(cat => cat.id === plate_structure.category_id)){
                plate_structure.price *= (100 - mode.discount) / 100
                plate_structure.price = Math.round(plate_structure.price * 100) / 100;
            }

            plates_structure.push(plate_structure)
        })

        plates = plates_structure

        // Maneja la respuesta
        console.log(response.data);
        this.plates = plates
        
        if(this.first_charge){
          document.getElementById("spinner").classList.add("d-none")
          this.first_charge=false
        }
      } catch (error) {
        // Maneja el error
        this.plates = []
        console.error('Error fetching shopping cart:', error);
        document.getElementById("spinner").classList.add("d-none")
      }
    },
    startPolling() {
      this.fetchShoppingCart();
      this.pollingInterval = setInterval(this.fetchShoppingCart, 15000); // Cada 15 segundos
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
    expandPlate(plate){
      if(plate.steps.length == 0){
        this.$refs.expanded.ChangePlate(plate);
      }else{
        this.$refs.menu_expanded.ChangePlate(plate, plate.steps.length+2, this.max_plates, this.totalPlates);
      }
    },
    ChangeAmount(plateId, change, comment){
      console.log("hola")
      let plateChanged = this.plates.filter(plate => plate.plate_id == plateId)[0]
      console.log(plateChanged)
      plateChanged.amount += change
      plateChanged.comment = comment
      console.log(plateChanged)
      this.changeAmountAPI(plateChanged.plate_id, plateChanged.amount, plateChanged.comment, plateChanged.id)
    },
    OpenDelete(id){
      let plateChanged = this.plates.filter(plate => plate.id == id)[0]
      this.plateToDelete = plateChanged;
      this.isDeleteModalVisible = true;
    },
    confirmDelete(id) {
      this.changeAmountAPI(this.plateToDelete.plate_id, 0, "", id)
      this.isDeleteModalVisible = false;
    },
    cancelDelete() {
      this.isDeleteModalVisible = false;
    },
    async changeAmountAPI(plate_id, new_amount, comment, carrito_id = null) {
      console.log(this.token)

      // Comprobacion maximo de cantidad de platos
      if(this.max_plates != null){
        let plate = this.plates.filter(plate => plate.plate_id == plate_id)[0]
        plate.amount = new_amount
        if (this.totalPlates > this.max_plates){
          this.fetchShoppingCart()
          let image = document.getElementById(plate_id+"_img")
          
          image.classList.add('shake');
          setTimeout(() => {
            image.classList.remove('shake');
          }, 500);
          
          this.showPlateMaxError = true;
          setTimeout(() => {
            this.showPlateMaxError = false;
          }, 1000);

          return
        }
      }

      document.getElementById("spinner").classList.remove("d-none")
      
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/addShoppingCart';
      const params = {
        id: carrito_id,
        plate_id: plate_id,
        amount: new_amount,
        comment: comment
      };
      try {
        const response = await axios.post(url, params, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        console.log(response.data);
        this.fetchShoppingCart()
        this.$emit('ReviseSession');
        document.getElementById("spinner").classList.add("d-none")
      } catch (error) {
        console.error('Error fetching shopping cart:', error);
        document.getElementById("spinner").classList.add("d-none")
      }
    },
    closeDeliveryPopUp() {
      this.is_delivery = false;
      this.is_take_away = false;
    },
    submitPersonalInfo() {
      this.errorMessage = '';
      if (!this.userInfo.name){this.errorMessage = 'Introduzca el nombre.'; return;}
      if (!this.phone_regex.test(this.userInfo.phone)){this.errorMessage = 'Introduzca un teléfono válido.'; return;}
      if (!this.userInfo.address){this.errorMessage = 'Introduzca una dirección válida.'; return;}
      if (!this.userInfo.piso){this.errorMessage = 'Introduzca cual es su piso.'; return;}
      if (!this.userInfo.codPostal){this.errorMessage = 'Introduzca cual es su código postal.'; return;}

      const url = process.env.VUE_APP_API_URL + '/v1/api/app/update_delivery';
      const token = this.token;

      // Ñapilla de coordenadas pq es un poco caos, no tocar, VA BIEN
      let longitude = this.userInfo.longitude
      this.userInfo.longitude = this.userInfo.latitude
      this.userInfo.latitude = longitude

      console.log(this.userInfo)

      document.getElementById("spinner").classList.remove("d-none");

      axios.put(url, this.userInfo, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        console.log(response.data);
        //this.is_delivery = false;
        this.$emit('ReviseSession');
        router.push({ name: "MisPedidos" });
      })
      .catch(error => {
        if (error.response && error.response.status === 412) {
          this.errorMessage = 'Estas demasiado lejos del restaurante para pedir online'
          longitude = this.userInfo.longitude
          this.userInfo.longitude = this.userInfo.latitude
          this.userInfo.latitude = longitude
        }
      })
      .finally(() => {
        document.getElementById("spinner").classList.add("d-none");
      });
    },
    submitPersonalInfoTakeAway() {
      this.errorMessage = '';
      if (!this.userInfo.name){this.errorMessage = 'Introduzca el nombre.'; return;}
      if (!this.phone_regex.test(this.userInfo.phone)){this.errorMessage = 'Introduzca un teléfono válido.'; return;}
      if (!this.email_regex.test(this.userInfo.email)){this.errorMessage = 'Introduzca un email válido.'; return;}

      const url = process.env.VUE_APP_API_URL + '/v1/api/app/update_take_away';
      const token = this.token;

      document.getElementById("spinner").classList.remove("d-none");

      axios.put(url, this.userInfo, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        console.log(response.data);
        //this.is_delivery = false;
        this.$emit('ReviseSession');
        router.push({ name: "MisPedidos" });
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        document.getElementById("spinner").classList.add("d-none");
      });
    },
    getDataBrowser(direccion, coords){
      this.userInfo.address = direccion
      this.userInfo.latitude = String(coords[1])
      this.userInfo.longitude = String(coords[0])
    },
  },
  created() {
    this.startPolling();
  },
  beforeUnmount() {
    this.stopPolling();
  }
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

  .shake {
    display: inline-block;
    animation: shake 0.5s ease-in-out;
    animation-iteration-count: infinite;
  }

  /* Definimos la animación para el gesto de negación */
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
  }

#header {
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

#header h1 {
  margin: 0;
  font-size: 24px;
}

#order-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#order-info #total-price {
  font-size: 20px;
  font-weight: bold;
}

.search-input{
  box-shadow: 0px;
  width: 90%;
  border: 0px;
}

.search-input:focus{
  border: 0px !important;
  outline: none;
}
  
.search-btn{
  display: flex;
  height: 55px;
  background: #fff;
  padding: 20px;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  border: 2px solid #dbdbdb;
  border-radius: 13px;
  color: #0A2533;
}

.price{
    color: #F47700;
  }

.botonCantidad{
    border: 2px solid #f47600a7;
    border-radius: 10px;
    color: #F47700;
  }

#finalize-order-button {
  background-color: #ff7300;
  color: white;
  border: none;
  padding: 10px;
  font-size: 15px;
  cursor: pointer;
  margin: auto;
  border-radius: 5rem;
  text-decoration: none;
}

.botonCantidad{
    border: 1px solid #f47600a7;
    border-radius: 10px;
    padding: 0px 7px 0px 7px;
    color: #F47700;
  }

  #div-platos{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  #div-platos img {
    width: 100%;
    object-fit: contain;
    background-color:white;
    border-radius: 10px;
    padding: 5px;
  }

  #div-platos p{
    max-height: 59px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom-scroll {
    padding-top: 10px;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 7px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }
  
  /* Asegura que el borde sea consistente con el input */
  .input-group .form-control {
    border-left: none !important; /* Elimina el borde izquierdo del input */
  }

  #div-platos img{
      height: 200px;
    }
    .plate{
      flex: 1 1 calc(16.66% - 15px);
      max-width: calc(16.66% - 15px);
    }

  @media (max-width: 1200px) {
    #div-platos img{
      height: 280px;
    }
    .plate{
      flex: 1 1 calc(25% - 15px);
      max-width: calc(25% - 15px);
    }
  }

  @media (max-width: 992px) {
    #div-platos img{
      height: 200px;
    }
    .plate{
      flex: 1 1 calc(25% - 15px);
      max-width: calc(25% - 15px);
    }
  }

  @media (max-width: 768px) {
    #div-platos img{
      height: 150px;
    }
    .plate{
      flex: 1 1 calc(33% - 15px);
      max-width: calc(33% - 15px);
    }
  }

  @media(max-width: 540px){
    .plate{
      flex: 1 1 calc(50% - 15px);
      max-width: calc(50% - 15px);
    }
  }

  @media(max-width: 300px){
    .plate{
      flex: 1 1 100%;
      max-width: 100%;
    }
  }

  .modal-dialog {
    height: 75vh; 
    min-width: 60%;
  }

  .modal-content {
    overflow-y: auto;
    max-height: 75vh;
  }

  @media (min-width: 767px) {
    .modal-dialog {
      width: 80%;
      min-height: 90vh; 
      min-width: 60%; 
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }
</style>