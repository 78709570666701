<template>
  <div v-if="showModal" class="modal fade show d-block z-3 d-flex align-items-center justify-content-center" tabindex="-1">
      <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
      <div class="modal-dialog z-2 m-4 d-flex align-items-center justify-content-center">
          <div class="modal-content p-2 custom-scroll">
              <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                  <h3 class="modal-title">Nueva Mesa</h3>
              </div>
              <div class="modal-body">
                  <form @submit.prevent="confirmAdd">
                      <div class="row">
                          <span id="span-error" class="text-danger text-center pb-3"></span>
                          <div class="col-md-6 mb-4">
                              <div class="form-group">
                                  <b for="table-name">Nº/Nombre de la Mesa:</b><br />
                                  <input type="text" id="table-name" v-model="newTable.number" class="form-control shadow rounded-3" placeholder="Ejemplo: Terraza 2" maxlength="30" />
                              </div>
                          </div>
                          <div class="col-md-6 mb-4">
                              <div class="form-group">
                                  <b for="table-dinners">Nº de Comensales:</b><br />
                                  <input type="number" id="table-dinners" v-model.number="newTable.max_diners" class="form-control shadow rounded-3" placeholder="Ejemplo: 4" min="1" required />
                              </div>
                          </div>
                          <div class="col-md-12 mb-5 d-flex align.items.center justify-content-center">
                            <div class="row">
                                <img v-if="imagePreview" :src="imagePreview" alt="Imagen de la mesa" class="img-fluid col-7 mx-auto" />
                            </div>
                          </div>
                      </div>
                      <div class="modal-footer align-items-center justify-content-center border-none">
                          <button type="submit" class="btn btn-add text-white px-4 rounded-3" style="background-color: #f47700">Añadir</button>
                          <button type="button" @click="closeModal" class="btn btn-danger px-4 rounded-3">Cancelar</button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/imagenMesaPredefinida.jpg';
  export default {
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            newTable: {
                number: '',
                max_diners: 1,
            },
            imagePreview: defaultImage,
        };
    },
    methods: {
        closeModal() {
            this.$emit('close-modal');
        },
        confirmAdd() {
            if (!this.newTable.number || !this.newTable.max_diners) {
              document.getElementById("span-error").textContent = "Debes rellenar todos los campos para continuar"
              return;
            }
            this.$emit('confirm-add', { ...this.newTable });
            this.newTable.number = '';
            this.newTable.max_diners = 1;
            this.closeModal();
        },
    },
  };
</script>

<style scoped>
  .modal { 
    background-color: rgba(139, 139, 139, 0.5); 
  }

  .modal-dialog {
    height: 90vh; 
    min-width: 60%;
  }

  .modal-content {
    overflow-y: auto;
    max-height: 90vh;
  }

  @media (min-width: 767px) {
    .modal-dialog {
      position: absolute;
      width: 80%;
      left: 28%;
      min-height: 90vh; 
      min-width: 60%; 
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  .custom-scroll {
    padding-top: 10px;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 7px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }
</style>
