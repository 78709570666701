<template>
  <div>
      <h1 class="text-center mt-4 fw-normal">Cuenta</h1>
      <div v-if="plates.length>0">
        <div class="px-3 mt-4" id="div-platos">
          <div class="row m-0 shadow rounded-4 py-3 mb-3 w-100" v-for="(plate, index) in plates" :key="index">
            <div class="col-5">
              <img :src="plate.photo ? plate.photo : noPhoto" class="rounded-4 img_responsive" alt="...">
            </div>
            <div class="col-7">
              <p class="mt-1 mb-1" style="font-size: 1rem;">{{ plate.name }}</p>
              <p class="mt-1 mb-1" style="font-size: 0.7rem; color: #F47700;">{{ plate.description }}</p>
              <div class="row d-flex justify-content-end mt-4">
                <span class="w-auto fw-medium" style="font-size: 0.9rem;">
                  {{ plate.price }}€ X {{ plate.amount }} = {{ plate.price * plate.amount }}€
                </span>
              </div>
            </div>
          </div>
        </div>
        <p class="text-end px-4 fw-bold fs-2">{{ total_price.toFixed(2) }}€</p>
        <div class="px-3 d-none" id="errorMessage">
          <div class="alert alert-danger text-center" role="alert">
            ¡No puedes pedir la cuenta si sigues esperando platos!
          </div>
        </div>
        <div v-if="!is_delivery && !is_take_away" class="w-100 d-flex justify-content-center mb-5 pb-3">
          <p @click="PedirCuenta()" id="finalize-order-button" class="px-5 shadow" :class="{'disabled': ['PENDIENTE', 'EN CURSO'].includes(this.state)}">
            Pedir la cuenta
          </p>
        </div>
      </div>
      <div v-else class="h-75 d-flex align-items-center justify-content-center">
        <div class="row m-0">
          <img class="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 mx-auto" src="../../src/assets/empty-cuenta.png"/>
          <h2 class="text-center mt-3 px-5">No es posible pedir la cuenta</h2>
        </div>
      </div>
      <SpinnerComponent id="spinner"></SpinnerComponent>
      <PedirCuenta></PedirCuenta>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import SpinnerComponent from '../components/SpinnerComponent.vue'
import PedirCuenta from '@/components/PedirCuenta.vue'
import noPhoto from './../assets/no_plate.png';

export default {
  name: 'HomeA',
  data: function() {
    return {
      state: null,
      total_price: null,
      plates: [],
      first_charge: true,
      noPhoto,
      is_delivery: false,
      is_take_away: false,
    }
  },
  components: {
    SpinnerComponent,
    PedirCuenta
  },
  computed:{
      ...mapState(['token']),
  },
  methods: {
    logout() {
      this.$store.commit('logout');
    },
    async PedirCuenta(){
      let errorDiv = document.getElementById("errorMessage")
      
      if (!['PENDIENTE', 'EN CURSO'].includes(this.state)){
        document.getElementById("order_bill").classList.remove("d-none")
        if (!errorDiv.classList.contains("d-none")){
          errorDiv.classList.add("d-none")
        }
      }else{
        if (errorDiv.classList.contains("d-none")){
          errorDiv.classList.remove("d-none")
        }
        
      }
    },
    async fetchGetCuenta() {
      const token = this.token; // Suponiendo que tienes el token almacenado en Vuex
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/MyOrders';
      try {
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        let orders = response.data.orders
        let mode = response.data.mode
        

        this.total_price = 0

        orders.forEach(order=>{
            let min_state = null

            let plates_structure = []

            if(order.deliveries.length > 0){
              this.is_delivery = true
            }
            if(order.take_aways.length > 0){
              this.is_take_away = true
            }
            
            order.shopping_carts.forEach(shopping_cart=>{
                let plate_structure = {}
                plate_structure.id = shopping_cart.id
                plate_structure.plate_id = shopping_cart.plate.id
                plate_structure.name = shopping_cart.plate.name
                plate_structure.description = shopping_cart.plate.description
                plate_structure.category=shopping_cart.plate.category
                plate_structure.photo = shopping_cart.plate.photo
                plate_structure.price = shopping_cart.plate.price
                plate_structure.amount = shopping_cart.amount
                plate_structure.comment = shopping_cart.comment
                plate_structure.steps = shopping_cart.plate.steps

                let i = 0
                plate_structure.steps.forEach(step=>{
                  if(shopping_cart.steps_selected.length > i){
                    step.plate_selected = shopping_cart.steps_selected[i].plate
                  }
                  i++
                })
                
                if (mode && plate_structure.category && !mode.excluded_categories.some(cat => cat.id === plate_structure.category.id)){
                    plate_structure.price *= (100 - mode.discount) / 100
                    plate_structure.price = Math.round(plate_structure.price * 100) / 100;
                }

                if (mode && !plate_structure.category){
                  if(plate_structure.plate_id == 'd368dc34-ca2a-4976-8834-9e5dd6e28e25'){
                    plate_structure.price = mode.adult_price
                  }else if(plate_structure.plate_id == 'ad9e80c6-fd8d-4d42-8227-89155ab7c210'){
                    plate_structure.price = mode.children_price
                  }
                }

                // Ordenos los estados de cada plato de mayor a menor
                shopping_cart.state_orders = shopping_cart.state_orders.sort((a, b) => {
                    return new Date(b.created_at)- new Date(a.created_at);
                });

                plate_structure.state = shopping_cart.state_orders[0].state
                if (min_state == null || min_state[0] > plate_structure.state.sort){
                    min_state = [plate_structure.state.sort, plate_structure.state.name]
                }

                plate_structure.state = plate_structure.state.name

                if (plate_structure.state != "PAGADO" && plate_structure.state != "CANCELADO"){
                  plates_structure.push(plate_structure)
                  this.total_price+=plate_structure.price * plate_structure.amount
                }                
            })

            order.plates = plates_structure

            order.state = min_state[1]
            order.min_state = min_state
        })

        let plates = []
        let min_state = null
        let plate_finded = undefined
        
        orders.forEach(order=>{
          if (min_state == null || min_state[0] > order.min_state[0]){
              min_state = [order.min_state[0], order.min_state[1]]
          }
          order.plates.forEach(shopping_cart=>{
              plate_finded = plates.find(item => item.plate_id === shopping_cart.plate_id)
              if(plate_finded === undefined){
                plates.push(shopping_cart)
              }else{
                plate_finded.amount += shopping_cart.amount
              }
              plate_finded = undefined
          })
          
        })

        this.state = min_state[1]
        // Maneja la respuesta
        this.plates = plates
        //this.total_price = response.data.price
        //this.state = response.data.state
        if(this.first_charge){
          document.getElementById("spinner").classList.add("d-none")
          this.first_charge=false
        }
      } catch (error) {
        // Maneja el error
        this.plates = []
        console.error('Error fetching my orders:', error);
        document.getElementById("spinner").classList.add("d-none")
      }
    },
    startPolling() {
      this.fetchGetCuenta();
      this.pollingInterval = setInterval(this.fetchGetCuenta, 5000); // Cada 5 segundos
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
  },
  created() {
    this.startPolling();
  },
  beforeUnmount() {
    this.stopPolling();
  }
}

</script>

<style scoped>
  #div-platos p{
    max-height: 59px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .disabled{
    background-color: rgb(178, 178, 178) !important;
  }

  .img_responsive{
    object-fit: contain;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
  }
</style>