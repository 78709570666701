<template>
    <div>
      <SpinnerComponent id="spinner"></SpinnerComponent>
      <div v-if="error == 1">
        <div class="container-fluid d-flex align-items-center justify-content-center min-vh-100">
            <div class="text-center">
                <div class="row d-flex align-items-center justify-content-center">
                    <img src="./../assets/ServerError.png" id="imagen" alt="Server Error">
                </div>
                <p class="text-center fs-5 fw-semibold" style="color:#f47600">UPS algo fue mal...</p>
            </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  import { mapState } from 'vuex';
  import SpinnerComponent from '../components/SpinnerComponent.vue'
  import axios from 'axios';
  import router from '@/router';

  export default {
    name: 'HomeA',
    data: function() {
          return {
            error: false,
            pollingInterval: null,
            delivery_name: this.$route.params.delivery_name
        }
      },
    components: {
        SpinnerComponent
    },
    computed:{
        ...mapState(['token']),
    },
    methods: {
        async LoginQr(){
            if (document.getElementById("spinner") && document.getElementById("spinner").classList.contains("d-none")) {
                document.getElementById("spinner").classList.remove("d-none");
            }
            if (this.token != "null" && this.token != null && this.token != undefined){
                let url_revise_token = process.env.VUE_APP_API_URL+'/v1/api/app/me_table_take_away'
                try {
                    await axios.get(url_revise_token, {
                        headers: {
                        'Authorization': `Bearer ${this.token}`
                        }
                    });
                    router.push({ name: "Home" });
                    return
                } catch (error) {
                    console.error('Token no valido: ', error);
                }
            }

            try {
                let url = process.env.VUE_APP_API_URL+'/v1/api/app/loginTakeAway?take_away_name='+this.delivery_name
                const response = await axios.post(url);
                
                this.$store.dispatch('setTokenTable', response.data);
                router.push({ name: "Home" });

            } catch (error) {
                document.getElementById("spinner").classList.add("d-none")
                this.error = 1
            }
            
        },
        startPolling() {
            this.LoginQr();
            this.pollingInterval = setInterval(this.LoginQr, 10000); // Cada 5 segundos
        },
        stopPolling() {
            clearInterval(this.pollingInterval);
        },
    },
    created() {
        this.startPolling();
    },
    beforeUnmount() {
        this.stopPolling();
    }
  }
</script>
  
<style scoped>
    #imagen {
        width: 80%;
        max-width: 400px;
        height: auto;
    }

    @media (max-width: 768px) {
        #imagen {
        width: 100%;
        }
    }

    @media (min-width: 768px) {
        p {
        font-size: 1.25rem;
        }
    }
</style>