<template>
  <div>
    <div v-if="visible" class="modal fade show d-block z-3 d-flex align-items-center justify-content-center" tabindex="-1" style="background-color: rgba(139, 139, 139, 0.5);" aria-modal="true" role="dialog">
      <div @click="$emit('close')" class="h-100 w-100 position-fixed z-1"></div>
      <div class="modal-dialog z-2 mb-5 d-flex align-items-center justify-content-center">
        <div class="modal-content rounded-4 mx-auto">
          <div class="modal-header flex-column align-items-center position-relative">
            <h3 class="modal-title">Mesa {{ order.table_number }}</h3>
            <span>{{ formatTime(order.date) }}</span>
            <div>
              <span v-if="dynamic_state === 'PENDIENTE'" class="badge bg-danger">Pendiente</span>
              <span v-else-if="dynamic_state === 'EN CURSO'" class="badge bg-warning">En curso</span>
              <span v-else-if="dynamic_state === 'EN CAMINO'" class="badge bg-info">En camino</span>
              <span v-else-if="dynamic_state === 'LISTO'" class="badge bg-info">LISTO</span>
              <span v-else-if="dynamic_state === 'ENTREGADO'" class="badge bg-success">Entregado</span>
              <span v-else-if="dynamic_state === 'PAGADO'" class="badge bg-primary">Pagado</span>
              <span v-else-if="dynamic_state === 'CANCELADO'" class="badge bg-grey">Cancelado</span>
            </div>
            <button type="button" class="position-absolute top-0 end-0 my-2 mx-3" style="color: #F47700;" aria-label="Close" @click="$emit('close')">
              <i class="fa-solid fa-xmark" style="font-size:25px"></i>
            </button>
          </div>
          <div class="modal-body" style="overflow-y: auto;">
            <div v-if="order.is_delivery" class="mb-4">
                <div class="w-100 d-flex">
                  <span class="bg-primary rounded-5 px-2 mx-auto">
                    delivery
                  </span>
                </div>
                <div class="my-2 mx-4 px-4 card text-center pt-3 border border-primary">
                  <div class="row m-0 d-flex align-items-center">
                    <p class="col">Nombre: <span class="fw-bold">{{ order.deliveries[0].name }}</span></p>
                    <p class="col">Teléfono: <a class="fw-bold" :href="order.deliveries[0]?.phone ? `tel:${order.deliveries[0].phone}` : '#'">
                      {{ order.deliveries[0]?.phone || 'No phone' }} <i class="fa-solid fa-phone ms-2"></i></a></p>
                  </div>
                  <p >Email: <span class="fw-bold">{{ order.deliveries[0].email }}</span></p>
                    
                  <p >Dirección: <a :href="`https://www.google.com/maps?q=${order.deliveries[0].latitude},${order.deliveries[0].longitude}`" target="_blank" class="fw-bold">{{ order.deliveries[0].address }} <i class="fa-solid fa-link ms-2"></i></a></p>
                  
                  <div class="row m-0 d-flex align-items-center">
                    <p class="col">Piso: <span class="fw-bold">{{ order.deliveries[0].piso }}</span></p>
                    <p class="col">CP: <span class="fw-bold">{{ order.deliveries[0].codPostal }}</span></p>
                  </div>
                  <div class="row m-0">
                    <p class="fw-bold text-warning col" v-if="order.deliveries[0].payWithCard">Pago con tarjeta <i class="fa-regular fa-credit-card  ms-2"></i></p>
                    <p class="fw-bold text-success col" v-else>Pago en efectivo <i class="fa-solid fa-money-bill-wave ms-2"></i></p>
                  </div>
                  <p class="col">Info: <span class="fw-bold">{{ order.deliveries[0].additionalInfo }}</span></p>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <button v-if="dynamic_state === 'PENDIENTE'" @click="updateDeliveryTakeAway(order.id, 'EN CURSO')" class="btn btn-warning">Marcar todo en curso</button>
                  <button v-if="dynamic_state === 'EN CURSO'" @click="updateDeliveryTakeAway(order.id, 'EN CAMINO')" class="btn btn-info">Marcar todo en camino</button>
                  <button v-if="dynamic_state === 'EN CAMINO'" @click="updateDeliveryTakeAway(order.id, 'ENTREGADO')" class="btn btn-success mx-2">Marcar todo entregado</button>
                  <button v-if="dynamic_state === 'EN CAMINO'" @click="updateDeliveryTakeAway(order.id, 'CANCELADO')" class="btn btn-secondary mx-2">Marcar todo cancelado</button>
                  <button v-if="dynamic_state === 'ENTREGADO'" @click="updateDeliveryTakeAway(order.id, 'CANCELADO')" class="btn btn-secondary">Marcar todo cancelado</button>
                  <button v-if="dynamic_state === 'CANCELADO'" @click="updateDeliveryTakeAway(order.id, 'PENDIENTE')" class="btn btn-danger">Marcar todo pendiente</button>
                </div>
            </div>
            <div v-else-if="order.is_take_away" class="mb-4">
                <div class="w-100 d-flex">
                  <span class="bg-warning rounded-5 px-2 mx-auto">
                    take away
                  </span>
                </div>
                <div class="my-2 mx-4 px-4 card text-center pt-3 border border-primary">
                  <div class="row m-0 d-flex align-items-center">
                    <p class="col">Nombre: <span class="fw-bold">{{ order.take_aways[0].name }}</span></p>
                    <p class="col">Teléfono: <a class="fw-bold" :href="order.take_aways[0]?.phone ? `tel:${order.take_aways[0].phone}` : '#'">
                      {{ order.take_aways[0]?.phone || 'No phone' }} <i class="fa-solid fa-phone ms-2"></i></a></p>
                  </div>
                  <p >Email: <span class="fw-bold">{{ order.take_aways[0].email }}</span></p>
                  <div class="row m-0">
                    <p class="fw-bold text-warning col" v-if="order.take_aways[0].payWithCard">Pago con tarjeta <i class="fa-regular fa-credit-card  ms-2"></i></p>
                    <p class="fw-bold text-success col" v-else>Pago en efectivo <i class="fa-solid fa-money-bill-wave ms-2"></i></p>
                  </div>
                  <p class="col">Info: <span class="fw-bold">{{ order.take_aways[0].additionalInfo }}</span></p>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <button v-if="dynamic_state === 'PENDIENTE'" @click="updateDeliveryTakeAway(order.id, 'EN CURSO')" class="btn btn-warning">Marcar todo en curso</button>
                  <button v-if="dynamic_state === 'EN CURSO'" @click="updateDeliveryTakeAway(order.id, 'LISTO')" class="btn btn-info">Marcar todo listo</button>
                  <button v-if="dynamic_state === 'LISTO'" @click="updateDeliveryTakeAway(order.id, 'ENTREGADO')" class="btn btn-success mx-2">Marcar todo entregado</button>
                  <button v-if="dynamic_state === 'LISTO'" @click="updateDeliveryTakeAway(order.id, 'CANCELADO')" class="btn btn-secondary mx-2">Marcar todo cancelado</button>
                  <button v-if="dynamic_state === 'ENTREGADO'" @click="updateDeliveryTakeAway(order.id, 'CANCELADO')" class="btn btn-secondary">Marcar todo cancelado</button>
                  <button v-if="dynamic_state === 'CANCELADO'" @click="updateDeliveryTakeAway(order.id, 'PENDIENTE')" class="btn btn-danger">Marcar todo pendiente</button>
                </div>
            </div>
            <div>
              <div v-if="pendingOrInProgressPlates.length==0">
                <div class="d-flex justify-content-center">
                  <img class="w-25" src="./../assets/full_order.png">
                </div>
                <p class="text-center">No hay platos pendientes</p>
              </div>
              <div v-for="plate in pendingOrInProgressPlates" :key="plate.id" class="card border-none shadow mb-3 rounded-4">
                <div class="row g-0 h-100">
                  <div class="col-4 d-flex align-items-center justify-content-center p-0">
                    <img :src="plate.photo ? plate.photo : noPhoto" :alt="plate.name" class="img-fluid d-block bg-light rounded-start img-fixed"/>
                  </div>
                  <div class="col-8 d-flex flex-column p-0">
                    <div v-if="plate.state === 'PENDIENTE'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="text-danger fs-6">Pendiente</b>
                      <div class="row mt-5">
                        <div class="col-lg-6 mb-2">
                          <button class="btn bg-grey w-100 rounded-3" @click="updatePlateState(plate.shopping_cart_id, 'CANCELADO')">
                            Cancelar
                          </button>
                        </div>
                        <div class="col-lg-6">
                          <button class="btn text-white w-100 rounded-3" style="background-color: #F47700;" @click="updatePlateState(plate.shopping_cart_id, 'EN CURSO')">
                            En curso
                          </button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="plate.state === 'EN CURSO'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6" style="color: #F47700;">En curso</b>
                      <div class="row mt-5">
                        <div class="col-lg-6 mb-2">
                          <button class="btn btn-danger w-100 rounded-3" @click="updatePlateState(plate.shopping_cart_id, 'PENDIENTE')">Pendiente</button>
                        </div>
                        <div class="col-lg-6" v-if="!order.is_delivery && !order.is_take_away">
                          <button class="btn btn-success w-100 rounded-3"  @click="updatePlateState(plate.shopping_cart_id, 'ENTREGADO')">Entregado</button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="plate.state === 'EN CAMINO'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6" style="color: #00f4b9;">En camino</b>
                    </div>
                    <div v-else-if="plate.state === 'LISTO'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6" style="color: #00f4b9;">Listo</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4 d-flex align-items-center">
                <hr class="flex-grow-1" style="border-color: #F47700; border-width: 3px;" />
                <h5 class="mx-3 mt-2">Finalizados</h5>
                <hr class="flex-grow-1" style="border-color: #F47700; border-width: 3px;" />
              </div>
              <div v-if="finishedPlates.length==0">
                <div class="d-flex justify-content-center">
                  <img class="w-25" src="./../assets/empty_order.png">
                </div>
                <p class="text-center">No hay platos finalizados</p>
              </div>
              <div v-for="plate in finishedPlates" :key="plate.id" class="card border-none shadow mb-3 rounded-4">
                <div class="row g-0 h-100">
                  <div class="col-4 d-flex align-items-center justify-content-center p-0">
                    <img :src="plate.photo ? plate.photo : noPhoto" :alt="plate.name" class="img-fluid d-block bg-light rounded-start img-fixed"/>
                  </div>
                  <div class="col-8 d-flex flex-column p-0">
                    <div v-if="plate.state === 'ENTREGADO'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6 text-success">Entregado</b>
                      <div class="row mt-5">
                        <div class="col-lg-6 mb-2" v-if="!order.is_delivery && !order.is_take_away">
                          <button class="btn text-white w-100 rounded-3" style="background-color: #F47700;" @click="updatePlateState(plate.shopping_cart_id, 'EN CURSO')">En curso</button>
                        </div>
                        <div class="col-lg-6" v-if="!order.is_delivery && !order.is_take_away">
                          <button class="btn btn-primary w-100 rounded-3"  @click="updatePlateState(plate.shopping_cart_id, 'PAGADO')">Pagado</button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="plate.state === 'CANCELADO'" class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6 text-grey">Cancelado</b>
                      <div class="row mt-5" v-if="!order.is_delivery && !order.is_take_away">
                        <div class="col-lg-6 mb-2">
                          <button class="btn btn-danger w-100 rounded-3" @click="updatePlateState(plate.shopping_cart_id, 'PENDIENTE')">Pendiente</button>
                        </div>
                      </div>
                    </div>
                    <div v-else class="card-title m-2 pl-2">
                      <h5>{{ plate.name }}</h5>
                      <h6>x{{ plate.amount }}</h6>
                      <div v-for="step in plate.steps" :key="step.id">
                        <p class="px-2 fw-semibold mb-0">{{ step.name }}</p>
                        <p class="px-2">{{ step.plate_selected.name }}</p>
                      </div>
                      <p class="text-dark fw-bold" style="font-size: 0.9rem;">
                        {{ plate.comment }}
                      </p>
                      <b class="fs-6 text-primary">Pagado</b>
                      <div class="row mt-5">
                        <div class="col-lg-6 mb-2">
                          <button class="btn btn-success w-100 rounded-3" @click="updatePlateState(plate.shopping_cart_id, 'ENTREGADO')">Entregado</button>
                        </div>
                        <div class="col-lg-6">
                          <button class="btn bg-grey w-100 rounded-3"  @click="updatePlateState(plate.shopping_cart_id, 'CANCELADO')">Cancelar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL TIEMPO DE ESPERA ESTIMADO PARA EL DELIVERY -->
    <div v-if="show_delivery_time && order.is_delivery" class="modal d-flex align-items-center justify-content-center p-3" tabindex="-1" style="background: rgba(0, 0, 0, 0.5); max-height: 90%;">
      <div class="bg-none position-absolute top-0 start-0 w-100 h-100" @click="show_delivery_time=false"></div>
      <div class="modal-dialog modal-dialog-centered z-2" style="overflow-y: auto;">
        <div class="modal-content custom-scroll">
          <div class="modal-header">
            <h5 class="modal-title w-100 text-center">
              <i class="fa-solid fa-truck"></i> Tiempo estimado de entrega
            </h5>
            <button type="button" class="btn-close position-absolute end-0 mr-3" @click="show_delivery_time=false" aria-label="Close"></button>
          </div>
          <form @submit.prevent="submitDeliveryTime">
            <div class="modal-body">
              <div class="w-100">
                  <p class="text-grey mt-3">* Indica el tiempo estimado de entrega del pedido para informar al cliente</p>
                </div>
              <div class="input-group mb-2">
                <span class="input-group-text"><i class="fa-solid fa-clock"></i></span>
                <div class="form-floating flex-grow-1">
                  <input type="number" id="deliveryTime" required min="1" v-model="deliveryTime" class="form-control" placeholder="Tiempo de envío"/>
                  <label for="deliveryTime">Tiempo (en minutos): <span class="text-danger">*</span></label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn" style="background-color: #ff7300;"><i class="fa-solid fa-paper-plane text-white"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- MODAL TIEMPO DE ESPERA ESTIMADO PARA TAKE AWAY -->
    <div v-if="show_delivery_time && order.is_take_away" class="modal d-flex align-items-center justify-content-center p-3" tabindex="-1" style="background: rgba(0, 0, 0, 0.5); max-height: 90%;">
      <div class="bg-none position-absolute top-0 start-0 w-100 h-100" @click="show_delivery_time=false"></div>
      <div class="modal-dialog modal-dialog-centered z-2" style="overflow-y: auto;">
        <div class="modal-content custom-scroll">
          <div class="modal-header">
            <h5 class="modal-title w-100 text-center">
              <i class="fa-solid fa-clock"></i> Tiempo estimado de realización
            </h5>
            <button type="button" class="btn-close position-absolute end-0 mr-3" @click="show_delivery_time=false" aria-label="Close"></button>
          </div>
          <form @submit.prevent="submitDeliveryTime">
            <div class="modal-body">
              <div class="w-100">
                  <p class="text-grey mt-3">* Indica el tiempo estimado de la realización del pedido para informar al cliente</p>
                </div>
              <div class="input-group mb-2">
                <span class="input-group-text"><i class="fa-solid fa-clock"></i></span>
                <div class="form-floating flex-grow-1">
                  <input type="number" id="deliveryTime" required min="1" v-model="deliveryTime" class="form-control" placeholder="Tiempo de envío"/>
                  <label for="deliveryTime">Tiempo (en minutos): <span class="text-danger">*</span></label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn" style="background-color: #ff7300;"><i class="fa-solid fa-paper-plane text-white"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import noPhoto from './../assets/no_plate.png';

  export default {
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      order: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        noPhoto,
        show_delivery_time: false,
        deliveryTime: null,
        update_one_plate: null,
      };
  },
    computed: {
      pendingOrInProgressPlates() {
        return this.order.plates.filter(plate => plate.state === 'PENDIENTE' || plate.state === 'EN CURSO' || plate.state === 'EN CAMINO' || plate.state === 'LISTO');
      },
      finishedPlates() {
        return this.order.plates.filter(plate => plate.state === 'ENTREGADO' || plate.state === 'CANCELADO' || plate.state === 'PAGADO');
      },
      dynamic_state(){
        if(this.order){
          let min_state = 100
          let states = ["PENDIENTE", "EN CURSO", "EN CAMINO", "LISTO", "ENTREGADO", "CANCELADO", "PAGADO"]
          this.order.plates.forEach( plate => {
            if (states.indexOf(plate.state) < min_state){
              min_state = states.indexOf(plate.state)
            }
          })
          return states[min_state]
        }
        return null
      },
      ...mapState(['partner_token'])
    },
    methods: {
      formatTime(dateTime) {
        const parts = dateTime.split(' ');
        let timePart = parts[1].split(":");
        timePart = timePart[0]+":"+timePart[1]
        return timePart + 'h';
      },
      async updatePlateState(plateId, newState) {
        if((
        (this.order.is_delivery && this.order.deliveries[0].final_data==null)
        ||(this.order.is_take_away && this.order.take_aways[0].final_data==null)) 
        && newState != 'CANCELADO' && newState != 'PENDIENTE'){
          this.show_delivery_time = true
          this.update_one_plate = plateId
          return
        }

        const token = this.partner_token;
        const url = process.env.VUE_APP_API_URL+'/v1/api/app/ChangeStatePlate?shopping_plate_id='+ plateId +'&state=' + newState;
        try {
          await axios.put(url, {},{headers: {'Authorization': `Bearer ${token}`}});

          const plate = this.order.plates.find(p => p.shopping_cart_id === plateId);
          if (plate) {plate.state = newState;}

          if(this.order.is_delivery || this.order.is_take_away){
            // Esto es para el delivery time para que se llame a la función updateDelivery que es la que internamente llama a deleteDeliveryDate
            const allStates = this.order.plates.map(p => p.state);
            if (allStates.every(state => state === 'CANCELADO')) {
              await this.updateDeliveryTakeAway(this.order.id, 'CANCELADO');
            } else if (allStates.every(state => state === 'PENDIENTE')) {
              await this.updateDeliveryTakeAway(this.order.id, 'PENDIENTE');
            }
          }

          this.$emit('fetchOrders');
        } catch (error) {
          // Maneja el error
          console.error('Error changing state:', error);
        }
      },
      async updateDeliveryTakeAway(order_id, newState) {
        if(((this.order.is_delivery && this.order.deliveries[0].final_data==null)
        ||(this.order.is_take_away && this.order.take_aways[0].final_data==null)) 
        && newState != 'CANCELADO' && newState != 'PENDIENTE'){
          this.show_delivery_time = true
          this.update_one_plate = null
          return
        }
        const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
        console.log(token)
        const url = process.env.VUE_APP_API_URL+'/v1/api/app/ChangeStateOrder_Delivery_TakeAway?order_id='+ order_id +'&state_name=' + newState;
        try {
          const response = await axios.put(url, {},{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log(response.data);
            this.order.plates.forEach(plate => {
                plate.state = newState;
            });
            
            // Si el pedido se cancela o se pone nuevamente en pendiente, eliminar el tiempo estimado de entrega
            if (newState === 'CANCELADO' || newState === 'PENDIENTE') {
                await this.deleteDeliveryDate(order_id);
            }
            
            this.$emit('fetchOrders');
        } catch (error) {
          // Maneja el error
            console.error('Error changing state:', error);
        }
      },
      async submitDeliveryTime(){
        const token = this.partner_token;
        let url;
        if(this.order.is_delivery){
          url = process.env.VUE_APP_API_URL+'/v1/api/app/update_delivery_date?minutes='+this.deliveryTime+'&order_id='+ this.order.id;
        }else{
          url = process.env.VUE_APP_API_URL+'/v1/api/app/update_take_away_date?minutes='+this.deliveryTime+'&order_id='+ this.order.id;
        }
        
        try {
          const response = await axios.put(url, {},{
            headers: {
                'Authorization': `Bearer ${token}`
            }
          });
            console.log(response.data);
            
            let currentDate = new Date();
            if(this.order.is_delivery){
              this.$emit('updateOrderDateDelivery', currentDate.setMinutes(currentDate.getMinutes() + this.deliveryTime));
            }else if(this.order.is_take_away){
              this.$emit('updateOrderDateTakeAway', currentDate.setMinutes(currentDate.getMinutes() + this.deliveryTime));
            }
            
            
            if(this.update_one_plate == null){
              await this.updateDeliveryTakeAway(this.order.id, 'EN CURSO');
            }else{
              await this.updatePlateState(this.update_one_plate, 'EN CURSO');
            }
            

            this.show_delivery_time = false;
        } catch (error) {
          // Maneja el error
            console.error('Error changing state:', error);
        }
      },
      async deleteDeliveryDate(order_id) {
        const token = this.partner_token;
        let url;
        if(this.order.is_delivery){
          url = process.env.VUE_APP_API_URL + '/v1/api/app/delete_delivery_date?order_id=' + order_id;
        }else{
          url = process.env.VUE_APP_API_URL + '/v1/api/app/delete_take_away_date?order_id=' + order_id;
        }
        
        try {
            const response = await axios.put(url, {}, { headers: { 'Authorization': `Bearer ${token}` } });
            console.log(response.data);
        } catch (error) {
            console.error('Error deleting delivery date:', error);
        }
      }
    }
  };
</script>

<style scoped>
  .img-fixed {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
  }

  .custom-modal-container {
    position: fixed;
    min-height: 95vh;
    background-color: rgba(139, 139, 139, 0.5);
    display: flex;
  }

  .modal-content {
    max-height: 78vh;
    overflow-y: auto;
    width: 100%;
  }
</style>