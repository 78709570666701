<template>
  <div>
      <h1 class="text-center mt-4 fw-normal titulo">Carta</h1>
      <div class="search-btn mx-4 mt-3">
          <i class="fa-solid fa-search "></i>
          <input  autocomplete="off" class="search-input"  placeholder="Buscar" v-model="search">
      </div>
      <div class="mt-3 ms-4 pb-2 " id="categories-nav">
        <span class="btn rounded-5 px-3 me-3 shadow-sm" :class="{ 'active': category.active}" 
          v-for="(category, index) in categories" :key="index" @click="ChangeCategory(category)">
          {{category.name}}
        </span>
      </div>
      <div>
        <div v-if="this.FilterPlates().length == 0" class="col-9 mx-auto mt-5 row m-0" style="height: 100%;">
            <img src="./../assets/not_plates_founds.png" class="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
            <p class="text-center fs-4 fw-semibold mt-4">¡No hemos encontrado platos para ti!</p>
        </div>
        <div class="mx-0 px-3 mt-2 mb-5" id="div-platos">
          <div class="plate shadow rounded-4 pb-2 mb-3" v-for="(plate, index) in this.FilterPlates()" :key="index" >
            <img :src="plate.photo ? plate.photo : noPhoto" :id="plate.plate_id" class="card-img-top rounded-4" alt="..." @click="expandPlate(plate)">
            <p class="mt-2 text-center mb-1" style="font-size: 0.9rem;">{{ plate.name }}</p>
            <div class="row d-flex justify-content-between">
              <span class="ms-4 mt-1 w-auto price fw-semibold px-0">{{ plate.price }}€</span>
              <span class="w-auto card-arrow me-4 px-2 py-1 d-flex align-items-center" style="border-radius: 100px" v-if="plate.amount == 0">
                <i class="fa-solid fa-plus text-white" @click="ChangeAmount(plate.plate_id, 1)"></i>
              </span>
              <div class="w-auto ps-0 me-2 " v-else>
                <i class="fa-regular fa-trash-can botonCantidad py-1 me-2 px-1" v-if="plate.amount && plate.amount == 1" @click="OpenDelete(plate.plate_id)"></i>
                <i class="fa-solid fa-minus botonCantidad py-1 me-2 px-1" @click="ChangeAmount(plate.plate_id, -1)" v-else></i>
                <span style="color: #19344F" class="fw-bold">{{plate.amount}}</span>
                <i class="fa-solid fa-plus botonCantidad py-1 ms-2 px-1" @click="ChangeAmount(plate.plate_id, 1)"></i>
              </div>
            </div>
          </div>
        </div>
      </div> 

    <!-- POPUP CONFIRMACIÓN PARA ORDENADOR (ABAJO DELECHA) -->
    <div v-if="showPlateMaxError" class="position-fixed end-0 bg-white border shadow-lg p-3 rounded mb-3 mr-5 d-none d-md-flex align-items-center" style="z-index: 1000; bottom: 13%;">
      <h6 class="mt-2"><i class="fa-solid fa-circle-xmark text-danger"></i> Has alzanzado el máximo de platos por mesa</h6>
    </div>

    <!-- POPUP CONFIRMACIÓN PARA MOVIL (ALIBA CENTLO) -->
    <div v-if="showPlateMaxError" class="position-fixed top-0 start-0 w-100 h-100 d-md-none d-flex align-items-center justify-content-center" style="background-color: rgba(139, 139, 139, 0.5); z-index: 1000;">
      <div class="bg-white border shadow-lg p-3 rounded w-100 mx-3">
        <h6 class="mt-2 text-center"><i class="fa-solid fa-circle-xmark text-danger fs-5"></i><br/>Has alzanzado el máximo de platos por mesa</h6>
      </div>
    </div>
    <PlateExpanded ref="expanded" @ChangeAmount="ChangeAmount"></PlateExpanded>
    <MenuSteps ref="menu_expanded" @MenuAdded="MenuAdded"></MenuSteps>
    <DeleteProductConfirm
      :visible="isDeleteModalVisible"
      :plate="plateToDelete"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
    ></DeleteProductConfirm>
    <SpinnerComponent id="spinner"></SpinnerComponent>
  </div>
</template>

<script>
import DeleteProductConfirm from '@/components/DeleteProductConfirm.vue'
import axios from 'axios';
import PlateExpanded from '../components/PlateExpanded.vue'
import MenuSteps from '../components/MenuSteps.vue'
import SpinnerComponent from '../components/SpinnerComponent.vue'
import { mapState } from 'vuex';
import $ from 'jquery';
import router from '@/router';
import noPhoto from './../assets/no_plate.png';

export default {
  name: 'HomeA',
  data: function() {
        return {
          plates: [],
          categories: [],
          pollingInterval: null,
          activeCategoryId: "",
          search: "",
          isDeleteModalVisible: false,
          plateToDelete: null,
          fallidos: 0,
          first_charge: true,
          noPhoto,
          totalPlates: 0,
          showPlateMaxError: false,
        }
    },
  components: {
    PlateExpanded,
    DeleteProductConfirm,
    SpinnerComponent,
    MenuSteps
  },
  computed:{
      ...mapState(['token']),
  },
  props: {
    // Declaramos la prop 'message' que recibimos del padre
    max_plates: {
      type: Number,
    },
    mode: {
      type: Object,
    }
  },
  methods: {
    FilterPlates(){
      let plates_filtered = this.plates.filter(
        plate => plate.category_id && plate.category_id.includes(this.activeCategoryId)
      );

      if (this.search != ""){
        plates_filtered = plates_filtered.filter(plate => plate.name.toString().toLowerCase().includes(this.search.toString().toLowerCase()) || plate.description.toString().toLowerCase().includes(this.search.toString().toLowerCase()))
      }
      plates_filtered.sort((a, b) => a.category.position - b.category.position)
      return plates_filtered
    },
    async fetchHome() {
      const token = this.token; // Suponiendo que tienes el token almacenado en Vuex
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/platesHome';
      try {
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Maneja la respuesta
        console.log(response.data);
        let plates = response.data.plates
        let mode = response.data.mode
        // LLAMO ENPOINT CARRITO PARA HACER EL CRUZAO
        const url_carrito = process.env.VUE_APP_API_URL+'/v1/api/app/shoppingCartHome';
        const response_carrito = await axios.get(url_carrito, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        let plates_structure = []
        plates.forEach(plate =>{
            let plate_structure = {}
            if(plate.category){
              plate_structure.category_id=plate.category.id
            }else{
              plate_structure.category_id=null
            }
            plate_structure.category = plate.category
            plate_structure.amount = 0
            plate_structure.plate_id = plate.id
            plate_structure.name = plate.name
            plate_structure.description = plate.description
            plate_structure.photo = plate.photo
            plate_structure.price = plate.price
            
            plate_structure.steps = plate.steps
            
            console.log("AQUI:" + this.mode)
            if (mode && !mode.excluded_categories.some(cat => cat.id === plate_structure.category_id)){
                plate_structure.price *= (100 - mode.discount) / 100
                plate_structure.price = Math.round(plate_structure.price * 100) / 100;
            }

            plates_structure.push(plate_structure)
        })

        plates = plates_structure

        this.totalPlates = response_carrito.data.plates_ordered
        let shopping_carts = response_carrito.data.shopping_carts
        
        shopping_carts.forEach(shopping_cart =>{
            console.log(shopping_cart)
            let plate_added = plates.find(plate => plate.plate_id == shopping_cart.plate.id)
            if (plate_added){
              plate_added.comment = shopping_cart.comment
              plate_added.amount = shopping_cart.amount
            }
        })
        console.log(plates)
        this.plates = plates
        this.fallidos = 0
        if(this.first_charge){
          document.getElementById("spinner").classList.add("d-none")
          this.first_charge=false
        }
      } catch (error) {
        // Maneja el error
        this.plates = []
        if(this.first_charge){
          document.getElementById("spinner").classList.add("d-none")
          this.first_charge=false
        }
        console.error('Error fetching shopping cart:', error);
        this.fallidos++
        if (this.fallidos > 3){
          router.push({ name: "Unauthorized" });
        }
      }
    },
    async fetchCategories() {
      const token = this.token; // Suponiendo que tienes el token almacenado en Vuex
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/Categories';
      try {
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Maneja la respuesta
        console.log(response.data);
        this.categories = response.data
        let all = {
          id: "",
          name: "Todo",
          active: true
        }
        this.categories.unshift(all)
      } catch (error) {
        // Maneja el error
        this.categories = []
        console.error('Error fetching categories:', error);
      }
    },
    startPolling() {
      this.fetchCategories();
      this.fetchHome();
      this.pollingInterval = setInterval(this.fetchHome, 15000); // Cada 15 segundos
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
    expandPlate(plate){
      if(plate.steps.length == 0){
        this.$refs.expanded.ChangePlate(plate);
      }else{
        this.$refs.menu_expanded.ChangePlate(plate, 1, this.max_plates, this.totalPlates);
      }
    },
    openMenuSteps(plate){
      this.$refs.menu_expanded.ChangePlate(plate, 1, this.max_plates, this.totalPlates);
    },
    ChangeCategory(category){
      this.categories.forEach(cat =>{
        if(category == cat){
          cat.active = true
          this.activeCategoryId = cat.id
        }else{
          cat.active = false
        }
      })
    },
    async ChangeAmount(plateId, change, comment){
      console.log(plateId)
      console.log(change)
      let plateChanged = this.plates.find(plate => plate.plate_id == plateId)
      if (plateChanged.steps.length > 0){
        this.openMenuSteps(plateChanged)
        return
      }
      console.log(plateChanged)
      
      plateChanged.comment = comment
      console.log(plateChanged)
      let respuesta = await this.changeAmountAPI(plateChanged.plate_id, change, plateChanged.comment)
      console.log("RESPUESTA: " + respuesta)
      if(change > 0 && plateChanged.amount == 1 && respuesta){
        this.flyToElement($('#'+plateId), $('.carrito'));
      }
    },
    async changeAmountAPI(plate_id, change, comment) {
      console.log(this.token)
      let plate = this.plates.filter(plate => plate.plate_id == plate_id)[0]
      plate.amount += change
      // Comprobacion maximo de cantidad de platos
      if(this.max_plates != null){
        if (change > 0 && this.totalPlates >= this.max_plates){
          this.fetchHome()
          let image = document.getElementById(plate_id)

          image.classList.add('shake');
          setTimeout(() => {
            image.classList.remove('shake');
          }, 500);

          this.showPlateMaxError = true;
          setTimeout(() => {
            this.showPlateMaxError = false;
          }, 1000);

          return false
        }
      }

      document.getElementById("spinner").classList.remove("d-none")

      const url = process.env.VUE_APP_API_URL+'/v1/api/app/addShoppingCart';
      const params = {
        plate_id: plate_id,
        amount: plate.amount,
        comment: comment
      };
      try {
        const response = await axios.post(url, params, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        console.log(response.data);
        this.fetchHome()
        this.$emit('ReviseSession');
        document.getElementById("spinner").classList.add("d-none")
        return true
      } catch (error) {
        console.error('Error fetching shopping cart:', error);
        document.getElementById("spinner").classList.add("d-none")
        return false
      }
      
    },
    MenuAdded(plateId){
      console.log("AQUIIIII HOMEEE")
      console.log(plateId)
      this.flyToElement($('#'+plateId), $('.carrito'));
      this.fetchHome()
      this.$emit('ReviseSession');
    },
    OpenDelete(plateId){
      let plateChanged = this.plates.find(plate => plate.plate_id == plateId)
      console.log(plateChanged)
      this.plateToDelete = plateChanged;
      this.isDeleteModalVisible = true;
    },
    confirmDelete() {
      this.ChangeAmount(this.plateToDelete.plate_id, -1)
      this.isDeleteModalVisible = false;
    },
    cancelDelete() {
      this.isDeleteModalVisible = false;
    },
    flyToElement(flyer, flyingTo) {
      console.log(flyer)
      console.log(flyingTo)
      var divider = 3;
      var initialScale = 0.5;
      var flyerClone = $(flyer).clone();
      $(flyerClone).css({position: 'absolute', top: $(flyer).offset().top + "px", left: $(flyer).offset().left + "px", opacity: 1, 'z-index': 1000,width: $(flyer).width() * initialScale + "px", height: $(flyer).height() * initialScale + "px",});
      $('body').append($(flyerClone));
      var gotoX = $(flyingTo).offset().left + ($(flyingTo).width() / 2) - ($(flyer).width()/divider)/2;
      var gotoY = $(flyingTo).offset().top + ($(flyingTo).height() / 2) - ($(flyer).height()/divider)/2;
      
      $(flyerClone).animate({
          opacity: 0.4,
          left: gotoX,
          top: gotoY,
          width: $(flyer).width()/divider,
          height: $(flyer).height()/divider
      }, 700,
      function () {
          $(flyingTo).fadeOut('fast', function () {
              $(flyingTo).fadeIn('fast', function () {
                  $(flyerClone).fadeOut('fast', function () {
                      $(flyerClone).remove();
                  });
              });
          });
      });
    }
  },
  created() {
    this.startPolling();
  },
  beforeUnmount() {
    this.stopPolling();
  }
}
</script>

<style scoped>
  *{
    color: #0A2533;
  }

  .search-input{
    box-shadow: 0px;
    width: 90%;
    border: 0px;
  }

  .search-input:focus{
    border: 0px !important;
    outline: none;
  }

  .search-btn{
    display: flex;
    height: 55px;
    background: #fff;
    padding: 20px;
    font-size: 18px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    border: 2px solid #dbdbdb;
    border-radius: 13px;
    color: #0A2533;
  }

  #categories-nav {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
  }

  #categories-nav span{
    background-color: #f1f5f5;
    font-size: 1rem;
  }

  #categories-nav .active{
    background-color: #F47700;
    color: white;
  }

  #categories-nav::-webkit-scrollbar{
    height: 2px;
  }

  #categories-nav::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }

  .price{
    color: #F47700;
  }

  .card-arrow{
    background-color:#F47700;
  }

  #div-platos{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  #div-platos img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
  }

  #div-platos p{
    max-height: 59px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .botonCantidad{
    border: 2px solid #f47600a7;
    border-radius: 10px;
    color: #F47700;
  }

  #div-platos img{
      height: 200px;
    }
    .plate{
      flex: 1 1 calc(16.66% - 15px);
      max-width: calc(16.66% - 15px);
    }

  @media (max-width: 1200px) {
    #div-platos img{
      height: 280px;
    }
    .plate{
      flex: 1 1 calc(25% - 15px);
      max-width: calc(25% - 15px);
    }
  }

  @media (max-width: 992px) {
    #div-platos img{
      height: 200px;
    }
    .plate{
      flex: 1 1 calc(25% - 15px);
      max-width: calc(25% - 15px);
    }
  }


  @media (max-width: 768px) {
    #div-platos img{
      height: 150px;
    }
    .plate{
      flex: 1 1 calc(33% - 15px);
      max-width: calc(33% - 15px);
    }
  }

  @media(max-width: 540px){
    .plate{
      flex: 1 1 calc(50% - 15px);
      max-width: calc(50% - 15px);
    }
  }
  @media(max-width: 300px){
    .plate{
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
</style>