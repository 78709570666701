<template>
  <div id="menu-expanded" class="d-none">
    <div @click="Close()" class="h-100 w-100 position-fixed z-1"></div>
    <div class="bg-white expanded rounded-4 z-2" style="max-height:79%;">
      <div class="row px-5 pt-4 m-0"  v-if="step>1">
        <span class="col mx-2 mt-2 rounded-5" :class="step==1 ? 'bg-dark' : 'bg-grey'" style="height: 3px;"></span>
        <span class="col mx-2 mt-2 rounded-5" v-for="(step_i, index) in plate.steps" :key="index" style="height: 3px;" :class="step==index+2 ? 'bg-dark' : 'bg-grey'"></span>
        <span class="col mx-2 mt-2 rounded-5" :class="plate.steps && step==plate.steps.length+2 ? 'bg-dark' : 'bg-grey'" style="height: 3px;"></span>
      </div>
      <div class="mt-4">
        <div v-if="step==1">
          <div class="pt-0">
            <h4 class="text-center">{{ plate.name }}</h4>
            <p class="text-center py-2">{{ plate.description }}</p>
            <div class="row">
              <img :src="plate.photo ? plate.photo : noPhoto" class="mx-auto col-10 h-auto">
            </div>
            <h3 class="mt-4 text-warning text-center fw-bold mb-0">{{ plate.price }}€</h3>
          </div>
        </div>
        <div v-for="(step_item, index) in plate.steps" :key="index" >
            <div v-if="step==index+2" class="pt-0 pr-2 overflow-y-scroll" style="height: 53vh;">
              <h4>{{step_item.name}}</h4>
              <h5>Elija 1:</h5>
              <span :id="'error_step_' + step_item.id" class="text-danger d-none">Elija un plato para continuar</span>
              <div class="mb-3 rounded border border-3 py-2 pe-2" v-for="(plate_step, index_plate) in step_item.plates" :key="index_plate" 
                  style="position: relative;" :class="step_item.plate_selected && step_item.plate_selected.id == plate_step.id ? 'border-warning' : 'border-grey'"
                  @click="step_item.plate_selected = plate_step">
                <p class="text-end" style="position: absolute; right: 10px; top: 10px;">
                  <i class="fa-regular fa-circle-check fs-5" :class="step_item.plate_selected && step_item.plate_selected.id == plate_step.id ? 'text-warning' : 'text-grey'"   @click="expandPlate(plate_step)"></i>
                </p>
                <div class="row m-0">
                  <img class="col-5 h-auto" style="height: auto;" :src="plate_step.photo ? plate_step.photo : noPhoto"/>
                  <div class="col-7">
                    <h5>{{ plate_step.name }}</h5>
                    <p>{{ plate_step.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div v-if="step==last_step">
          <h4 class="text-center">Resumen:</h4>
          <div v-for="(step_item, index) in plate.steps" :key="index" class="mt-3">
            <p class="fw-bold mb-1">{{ step_item.name }}: </p>
            <span><i class="fa-regular fa-circle-check text-success me-1 fw-bold"></i>{{ step_item.plate_selected.name }}</span>
          </div>
          <hr>
          <textarea v-if="!is_carta" class="form-control mt-3" v-model="plate.comment" placeholder="Indica aquí tus observaciones:" style="height: 100px" maxlength="499"></textarea>
          <span class="text-danger" v-if="error">Ha ocurrido un error al añadir el menu al carrito</span>
        </div>
        <div v-if="step==0" class="d-flex align-items-center justify-content-center">
          <img src="@/assets/tick.gif" alt="Guardado Correctamente" class="img-fluid w-50 mx-auto" />
          <h3 class="text-success mb-3">¡Guardado Correctamente!</h3>
        </div>
        <div class="row py-4 px-3" v-if="step>0">
          <div class="col d-flex justify-content-start align-items-center mb-3" >
            <i v-if="step>1" class="fa-solid fa-arrow-left fs-5 p-2 text-white" 
              style="border-radius: 100%; background-color: #F47700; max-height: 35px" @click="step--"></i>
          </div>
          <div class="col d-flex justify-content-end">
            <i v-if="step<last_step" class="fa-solid fa-arrow-right fs-5 p-2 text-white" 
              style="border-radius: 100%; background-color: #F47700; max-height: 35px" @click="step++"></i>
            <i v-else-if="step==last_step && (!max_plates || totalPlates < max_plates) && !is_carta" class="fa-solid fa-check fs-5 p-2 text-white" 
              style="border-radius: 100%; background-color: #0CD613; max-height: 35px" @click="SaveSteps"></i>
            <span v-else-if="max_plates && totalPlates >= max_plates" class="text-danger text-center">
              Máximo de platos alcanzado
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import noPhoto from './../assets/no_plate.png';
  import axios from 'axios';
  import { mapState } from 'vuex';

  export default {
    name: 'PlateExpanded',
    data: function() {
        return {
          plate:{},
          noPhoto,
          step: 2,
          max_plates: null,
          totalPlates: 0,
          error: false
        }
      },
    props: {
      is_carta:{
          type: Boolean,
          required: false,
          default: false
      },
    },
    computed:{
        ...mapState(['token']),
        last_step() {
          // Verifica que steps exista y sea un array
          return this.plate.steps ? this.plate.steps.length + 2 : 2;
        },
        v_steps(){
          let spaces_steps = ["", ""]
          if(this.plate.steps){
            this.plate.steps.forEach(element => {
              spaces_steps.push("")
              console.log(element)
            });
          }
          return spaces_steps
        }
    },
    watch:{
      step(newValue, oldValue) {
        if(oldValue != 0 && newValue > oldValue){
          console.log("Voy para arriba")
          console.log(oldValue)
          console.log(newValue)
          console.log(this.plate)
          console.log(this.plate.steps.length)
          console.log(this.step)
          console.log(this.plate.steps)
            if (oldValue > 1 && this.plate.steps.length >= oldValue-1 && this.plate.steps[oldValue-2].plate_selected == null) {
              console.log("entro")
              let id = "error_step_"+this.plate.steps[oldValue-2].id
              console.log(id)
              document.getElementById(id).classList.remove("d-none")
              console.log(this.plate.steps[oldValue-2].plate_selected)
              this.step = oldValue
            }
        }
      },
    },
    methods: {
      Close(){
          document.getElementById("menu-expanded").classList.add("d-none")
          this.step=1
          this.plate = {}
      },
      ChangePlate(plate, step=1, max_plates, totalPlates){
        this.plate = plate
        this.step = step
        this.max_plates = max_plates
        this.totalPlates = totalPlates
        console.log("max_plates: " + max_plates)
        console.log("total_plates: " + totalPlates)
        document.getElementById("menu-expanded").classList.remove("d-none")
      },
      async SaveSteps(){
        console.log(this.plate)
        this.plate.amount = 1

        let plate = this.plate

        document.getElementById("spinner").classList.remove("d-none")
        const url = process.env.VUE_APP_API_URL+'/v1/api/app/addShoppingCartSteps';
        const params = plate
        try {
          const response = await axios.post(url, params, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          });
          console.log(response.data);
          this.step=0
          this.error = false
          document.getElementById("spinner").classList.add("d-none")
          setTimeout(() => {
            this.$emit('MenuAdded', this.plate.plate_id);
            this.$emit('fetchShoppingCart');
            this.Close()
          }, 3000);
          
        } catch (error) {
          document.getElementById("spinner").classList.add("d-none")
          this.error = true
          console.error('Error fetching shopping cart:', error);
        }
      },
    }
  }
</script>

<style scoped>
  #menu-expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(139, 139, 139, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100 !important;
    transition: all 3s ease;
    backdrop-filter: blur(4px);
  }

  #menu-expanded img {
    height: 200px;
    object-fit: cover;
  }

  .expanded{
    width: 85%;
    max-width: 450px;
    overflow-y: auto;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 12rem;
    margin-top: 5rem;
  }
  
  .description{
    padding: 0px 10px 0px 10px;
    max-height:150px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: scroll;
    text-overflow: ellipsis;
  }

  .description::-webkit-scrollbar{
    width: 4px;
  }

  .description::-webkit-scrollbar-thumb {
      background-color: #f47600ad;
      border-radius: 10px;
  }

  .botonCantidad{
    border: 2px solid #f47600a7;
    border-radius: 10px;
    color: #F47700;
  }

  .disabled{
    color: #b4b4b4a7;
    border: 2px solid #b4b4b4a7;
  }

  .expanded::-webkit-scrollbar {
    width: 0px;
  }

  @media (min-width: 800px) {
    .overflow-y-scroll::-webkit-scrollbar{
      background-color: #f47600ad;
      border-radius: 10px;
      width:5px
    }
  }
</style>