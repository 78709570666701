<template>
    <div id="plate-expanded" class="d-none">
      <div @click="Close()" class="h-100 w-100 position-fixed z-1">
      </div>
      <div class="bg-white expanded rounded-4 p-3 z-2">
        <img :src="plate.photo ? plate.photo : noPhoto" class="card-img-top rounded-4 mb-2" alt="...">
        <h5 class="text-center mb-3 px-1">{{ plate.name }}</h5>
        <p class="description mb-1">{{ plate.description }}</p>
        <textarea v-if="!is_carta" class="form-control" v-model="plate.comment" placeholder="Escribe un comentario (Ej: Sin tomate)" style="height: 100px" maxlength="499"></textarea>
        
        <div class="row" v-if="!is_carta">
          <button class="btn my-3 btn-dark mx-auto col-6" @click="SaveComment">Guardar</button>
        </div>
      </div>
      
    </div>
  </template>

<script>
import noPhoto from './../assets/no_plate.png';

export default {
  name: 'PlateExpanded',
  data: function() {
      return {
        plate:{},
        noPhoto
      }
    },
  props: {
    is_carta:{
        type: Boolean,
        required: false,
        default: false
    },
  },
  methods: {
    Close(){
        document.getElementById("plate-expanded").classList.add("d-none")
        this.plate = {}
    },
    ChangePlate(plate){
      this.plate = plate
      document.getElementById("plate-expanded").classList.remove("d-none")
    },
    SaveComment(){
      console.log(this.plate)
      let amount = 1
      if (this.plate.amount > 0){
        amount = 0
      }
      this.$emit('ChangeAmount', this.plate.plate_id, amount, this.plate.comment);
      this.Close()
    }
  }
}

</script>
  
  <style scoped>
  /* Estilo para el contenedor del spinner */
  #plate-expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(139, 139, 139, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100 !important;
    transition: all 3s ease;
    backdrop-filter: blur(4px);
  }

  #plate-expanded img {
    width: 100%; /* Hace que la imagen ocupe todo el ancho */
    height: 200px; /* Ajusta la altura según lo necesario */
    object-fit: contain; /* Mantiene la imagen completa sin recortes */
    background-color: white; /* Fondo blanco si la imagen no llena todo el espacio */
    border-radius: 10px; /* Opcional, para bordes redondeados */
    padding: 5px; /* Da espacio alrededor de la imagen */
  }

  .expanded{
    width: 90%;
    max-width: 450px;
  }
  .description{
    padding: 0px 10px 0px 10px;
    max-height:150px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: scroll;
    text-overflow: ellipsis;
  }

  .description::-webkit-scrollbar{
    width: 4px;
  }

  .description::-webkit-scrollbar-thumb {
      background-color: #f47600ad;
      border-radius: 10px;
  }

  .botonCantidad{
    border: 2px solid #f47600a7;
    border-radius: 10px;
    color: #F47700;
  }

  .disabled{
    color: #b4b4b4a7;
    border: 2px solid #b4b4b4a7;
  }
  </style>
  