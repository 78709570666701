<template>
  <div>
      <div class="powered">
        <span class="me-2">Powered by</span>
        <img style="width: auto; height: 14px;" src="../../src/assets/logo_Negro.png"/> 
      </div>
      <h1 class="text-center mt-4 fw-normal titulo">{{restaurant_name ? restaurant_name:"Carta"}}</h1>
      <div class="search-btn mx-4 mt-3">
          <i class="fa-solid fa-search "></i>
          <input  autocomplete="off" class="search-input"  placeholder="Buscar" v-model="search">
      </div>
      <div class="mt-3 ms-4 pb-2 " id="categories-nav">
        <span class="btn rounded-5 px-3 me-3 shadow-sm" :class="{ 'active': category.active}" 
          v-for="(category, index) in categories" :key="index" @click="ChangeCategory(category)">
          {{category.name}}
        </span>
      </div>
      <div>
        <div v-if="this.FilterPlates().length == 0" class="col-9 mx-auto mt-5 row m-0" style="height: 100%;">
            <img src="./../assets/not_plates_founds.png" class="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
            <p class="text-center fs-4 fw-semibold mt-4">¡No hemos encontrado platos para ti!</p>
        </div>
        <div class="mx-0 px-3 mt-2 mb-5" id="div-platos">
          <div class="plate shadow rounded-4 pb-2 mb-2" v-for="(plate, index) in this.FilterPlates()" :key="index" >
            <img :src="plate.photo ? plate.photo : noPhoto" :id="plate.plate_id" class="card-img-top rounded-4" alt="..." @click="expandPlate(plate)">
            <p class="mt-2 text-center mb-1" style="font-size: 0.9rem;">{{ plate.name }}</p>
            <div class="row d-flex justify-content-center">
              <span class="mt-1 w-auto price fw-semibold px-0">{{ plate.price }}€</span>
            </div>
          </div>
        </div>
      </div> 
    <PlateExpanded ref="expanded" @ChangeAmount="ChangeAmount" :is_carta="true"></PlateExpanded>
    <MenuSteps ref="menu_expanded" @MenuAdded="MenuAdded" :is_carta="true"></MenuSteps>
    <SpinnerComponent id="spinner"></SpinnerComponent>
  </div>
</template>

<script>
import axios from 'axios';
import PlateExpanded from '../components/PlateExpanded.vue'
import MenuSteps from '../components/MenuSteps.vue'
import SpinnerComponent from '../components/SpinnerComponent.vue'
import noPhoto from './../assets/no_plate.png';

export default {
  name: 'HomeA',
  data: function() {
        return {
          plates: [],
          categories: [],
          pollingInterval: null,
          activeCategoryId: "",
          search: "",
          isDeleteModalVisible: false,
          plateToDelete: null,
          fallidos: 0,
          first_charge: true,
          noPhoto,
          totalPlates: 0,
          showPlateMaxError: false,
          restaurant: null,
          restaurant_name: null
        }
    },
  components: {
    PlateExpanded,
    SpinnerComponent,
    MenuSteps
  },
  async mounted() {
      if (this.$route.params.restaurant){
        this.restaurant = this.$route.params.restaurant
      }
      this.fetchCategories();
      this.fetchHome();    
  },
  methods: {
    FilterPlates(){
      let plates_filtered = this.plates.filter(
        plate => plate.category_id && plate.category_id.includes(this.activeCategoryId)
      );

      if (this.search != ""){
        plates_filtered = plates_filtered.filter(plate => plate.name.toString().toLowerCase().includes(this.search.toString().toLowerCase()) || plate.description.toString().toLowerCase().includes(this.search.toString().toLowerCase()))
      }
      plates_filtered.sort((a, b) => a.category.position - b.category.position)
      return plates_filtered
    },
    async fetchHome() {
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/Carta?restaurant_id='+this.restaurant;
      try {
        const response = await axios.get(url);

        // Maneja la respuesta
        console.log(response.data);
        let plates = response.data.plates
        this.restaurant_name = response.data.restaurant_name

        let plates_structure = []
        plates.forEach(plate =>{
            let plate_structure = {}
            if(plate.category){
              plate_structure.category_id=plate.category.id
            }else{
              plate_structure.category_id=null
            }
            plate_structure.category = plate.category
            plate_structure.amount = 0
            plate_structure.plate_id = plate.id
            plate_structure.name = plate.name
            plate_structure.description = plate.description
            plate_structure.photo = plate.photo
            plate_structure.price = plate.price
            
            plate_structure.steps = plate.steps

            plates_structure.push(plate_structure)
        })

        plates = plates_structure

        this.plates = plates
        
        document.getElementById("spinner").classList.add("d-none")

      } catch (error) {
        // Maneja el error
        this.plates = []
        document.getElementById("spinner").classList.add("d-none")
        console.error('Error fetching shopping cart:', error);
      }
    },
    async fetchCategories() {
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/CartaCategories?restaurant_id='+this.restaurant;
      try {
        const response = await axios.get(url);

        // Maneja la respuesta
        console.log(response.data);
        this.categories = response.data
        let all = {
          id: "",
          name: "Todo",
          active: true
        }
        this.categories.unshift(all)
      } catch (error) {
        // Maneja el error
        this.categories = []
        console.error('Error fetching categories:', error);
      }
    },
    expandPlate(plate){
      if(plate.steps.length == 0){
        this.$refs.expanded.ChangePlate(plate);
      }else{
        this.$refs.menu_expanded.ChangePlate(plate, 1, this.max_plates, this.totalPlates);
      }
    },
    openMenuSteps(plate){
      this.$refs.menu_expanded.ChangePlate(plate, 1, this.max_plates, this.totalPlates);
    },
    ChangeCategory(category){
      this.categories.forEach(cat =>{
        if(category == cat){
          cat.active = true
          this.activeCategoryId = cat.id
        }else{
          cat.active = false
        }
      })
    }
  }
}
</script>

<style scoped>
  *{
    color: #0A2533;
  }

  .search-input{
    box-shadow: 0px;
    width: 90%;
    border: 0px;
  }

  .search-input:focus{
    border: 0px !important;
    outline: none;
  }

  .search-btn{
    display: flex;
    height: 50px;
    background: #fff;
    padding: 20px;
    font-size: 18px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    border: 2px solid #dbdbdb;
    border-radius: 13px;
    color: #0A2533;
  }

  #categories-nav {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
  }

  #categories-nav span{
    background-color: #f1f5f5;
    font-size: 1rem;
  }

  #categories-nav .active{
    background-color: #F47700;
    color: white;
  }

  #categories-nav::-webkit-scrollbar{
    height: 2px;
  }

  #categories-nav::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }

  .price{
    color: #F47700;
  }

  .card-arrow{
    background-color:#F47700;
  }

  #div-platos{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  #div-platos img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
  }

  #div-platos p{
    max-height: 59px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .botonCantidad{
    border: 2px solid #f47600a7;
    border-radius: 10px;
    color: #F47700;
  }

  #div-platos img{
      height: 200px;
    }
    .plate{
      flex: 1 1 calc(16.66% - 15px);
      max-width: calc(16.66% - 15px);
    }

  @media (max-width: 1200px) {
    #div-platos img{
      height: 280px;
    }
    .plate{
      flex: 1 1 calc(25% - 15px);
      max-width: calc(25% - 15px);
    }
  }

  @media (max-width: 992px) {
    #div-platos img{
      height: 200px;
    }
    .plate{
      flex: 1 1 calc(25% - 15px);
      max-width: calc(25% - 15px);
    }
  }


  @media (max-width: 768px) {
    #div-platos img{
      height: 150px;
    }
    .plate{
      flex: 1 1 calc(33% - 15px);
      max-width: calc(33% - 15px);
    }
  }

  @media(max-width: 540px){
    .plate{
      flex: 1 1 calc(50% - 15px);
      max-width: calc(50% - 15px);
    }
  }
  @media(max-width: 300px){
    .plate{
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
</style>