<template>
    <div class="select-menuAddress w-md-75" id="select-menu-provincia">
        <div class="input-group">
            <span class="input-group-text"><i class="fa-solid fa-location-dot text-dark"></i></span>
            <div class="form-floating flex-grow-1">
                <input ref="miInput" autocomplete="off" id="textbox-place" @focus="handleFocus" @blur="handleBlur" v-model.lazy="inputValue" @input="handleInput" placeholder="Dirección:" class="form-control py-0">
            </div>
        </div>
        <ul class="options z-1 custom-scroll" id="options-provincia" style="padding: 0px; overflow-y: scroll; overflow-x: hidden; "></ul>
    </div>
    <div class="col-xl-8 col-12 my-3" id="map" style="min-height: 280px; z-index: 0;"></div>
</template>
  
<script>
  import axios from 'axios';
  import L from 'leaflet';
  import 'leaflet.markercluster';
  
  export default {
    name: 'AddressBrowserComponent',
    props: {  address: { type: String, required: false }, latitud: { type: String, required: false }, longitud: { type: String, required: false } },
    data: function() {
          return {
            inputValue: "",
            typingTimer: null,
            centro: [40.4165, -3.70256],
            isCorrect: false,
            map: {},
            markers: L.markerClusterGroup(),
            userLocation: null,
          }
    },
    watch:{
        address(){
            this.inputValue = this.address
        },
        latitud(){
            console.log(this.centro)
            let parsedLatitud = Number(this.latitud);
            if (!isNaN(parsedLatitud)) {
                this.centro[1] = parsedLatitud;
            }
            console.log("ya he cambiado la latitud")
            if (typeof this.centro[0] === 'number' && typeof this.centro[1] === 'number'){
                this.MoverMapa()
                this.MeterMarcador(this.centro)
            }
        },
        longitud(){
            console.log(this.centro)
            let parsedLongitud = Number(this.longitud);
            if (!isNaN(parsedLongitud)) {
                this.centro[0] = parsedLongitud;
            }
            console.log("ya he cambiado la longitud")
            if (typeof this.centro[0] === 'number' && typeof this.centro[1] === 'number'){
                this.MoverMapa()
                this.MeterMarcador(this.centro)
            }
        }
    },
    methods: {
        cazado(){
            this.$refs.miInput.classList += " is-invalid"
            this.$refs.miInput.classList.remove("is-valid")
        },
        formatAddress(provincia) {
            let street = provincia.text || "";
            //let number = ""; COMENTADO PORQUE NO CONSIGO SACARLO DE LA API DE MAPBOX
            let postalCode = "";
            let city = "";
            let region = "";

            if (provincia.context) {
                provincia.context.forEach(context => {
                    if (context.id.includes("postcode")) {
                        postalCode = context.text;
                    } else if (context.id.includes("place")) {
                        city = context.text;
                    } else if (context.id.includes("region")) {
                        region = context.text;
                    }
                });
            }

            if(street != '' && city != '' && postalCode != '' && region != ''){
                return `${street}, ${city}, ${postalCode}, ${region}`;
            }
        },
        async AddCitySelects(){
            var provincias = [];
            try {
                let proximityParam = this.userLocation ? `&proximity=${this.userLocation[1]},${this.userLocation[0]}` : "";

                const response = await axios.get(
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.inputValue}.json?access_token=pk.eyJ1IjoiamJuMjAwMSIsImEiOiJjbGt3cW95NjMwM2JsM2tueWVrMHV0MnJoIn0.nI9ULLd2W_4P0JCku50Iww&country=es&language=es&limit=5${proximityParam}`
                );
                provincias = response.data.features;
            } catch (error) {
                console.error(error);
            }

            const cityUl = document.querySelector("#options-provincia");
            cityUl.innerHTML = "";

            provincias.forEach(provincia => {
                let formattedAddress = this.formatAddress(provincia);
                if(formattedAddress != undefined) {
                    var li = document.createElement("li");
                    li.classList = "option";
                    li.id = "option-provincia";
                    cityUl.appendChild(li);

                    var div = document.createElement("div");
                    div.classList = "mx-auto";
                    li.appendChild(div);

                    var span = document.createElement("p");
                    span.classList = "option-text pb-1 pt-3";
                    span.innerHTML = formattedAddress;
                    div.appendChild(span);

                    li.addEventListener("mousedown", () => {
                        this.$refs.miInput.value = formattedAddress;
                        this.inputValue = formattedAddress;
                        this.centro = [provincia.center[1], provincia.center[0]];

                        if (!this.$refs.miInput.classList.contains("is-valid")) {
                            this.$refs.miInput.classList += " is-valid";
                            this.$refs.miInput.classList.remove("is-invalid");
                            this.isCorrect = true;
                        }
                        this.PasarVariables();
                    });
                }
            });
        },
        handleInput() {
            if(!this.$refs.miInput.classList.contains("is-invalid")){
                this.$refs.miInput.classList += " is-invalid"
                this.$refs.miInput.classList.remove("is-valid")
                this.isCorrect = false
                
            }
            clearTimeout(this.typingTimer);

            // Iniciar un temporizador para verificar si el usuario ha dejado de escribir durante 1 segundo
            this.typingTimer = setTimeout(() => {
                this.onTypingStopped();
            }, 100);
            this.PasarVariables()
        },
        onTypingStopped() {
            this.inputValue = this.$refs.miInput.value
            this.AddCitySelects()
        },
        async handleFocus() {
            await this.AddCitySelects()
            const optionMenuProvincia = document.getElementById("select-menu-provincia")
            optionMenuProvincia.classList.toggle("active");
        },
        handleBlur() {
            // Este evento se ejecutará cuando el input pierda el foco
            const optionMenuProvincia = document.getElementById("select-menu-provincia")
            optionMenuProvincia.classList.toggle("active");
        },
        PasarVariables(){
            if(this.isCorrect){
                this.$emit("getDataBrowser", this.inputValue, this.centro);
                this.MoverMapa()
                this.MeterMarcador(this.centro)
            }
        },
        MeterMarcador(centro){
            var iconoPersonalizado  = L.icon({
                iconUrl: require('@/assets/marcador.png'),  // URL de la imagen del icono
                iconSize: [38, 40],     // Tamaño del icono
                iconAnchor: [22, 40],   // Punto del icono que corresponderá a la ubicación del marcador
                popupAnchor: [-3, -76],
            });
            var marker = L.marker(centro, {icon : iconoPersonalizado});
            this.markers.clearLayers();
            
            this.map.addLayer(this.markers);
            this.markers.addLayer(marker);
        },
        MoverMapa(){
            console.log("Me llega asi: " + this.centro)
            this.map = this.map.setView(this.centro, 15)
        }
    },
    mounted(){
        this.map = L.map('map', {zoomControl: true,zoom:1,zoomAnimation:true,fadeAnimation:true,markerZoomAnimation:true, scrollWheelZoom: false, dragging: false})
        //this.MoverMapa()
        this.map = this.map.setView([40.4165, -3.70256], 5)
        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
            maxZoom: 20,
            minZoom: 1
        }).addTo(this.map);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.userLocation = [position.coords.latitude, position.coords.longitude];
                    console.log("Ubicación del usuario:", this.userLocation);
                },
                error => {
                    console.warn("No se pudo obtener la ubicación del usuario", error);
                }
            );
        }
    }
  }
</script>
  
<style>
    /* ===== Google Font Import - Poppins ===== */
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
   
    .select-menuAddress {
        position: relative;
        width: 100%;
        max-width: 100%;
    }

    .select-menuAddress .select-btn{
        display: flex;
        height: 40px;
        background: #fff;
        padding: 20px;
        font-size: 1rem;
        font-weight: 400;
        border-radius: 8px;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        box-shadow: 0 0 5px rgba(0,0,0,0.1);
        border-radius: 5px;
        color: #c7c7c7;
    }

    .select-btn i{
        font-size: 0.8rem;
        transition: 0.3s;
        color: #c7c7c7;
    }

    .select-menuAddress.active .select-btn i{
        transform: rotate(-180deg);
    }

    .select-menuAddress .options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        display: none;
        z-index: 10;
    }

    .select-menuAddress.active .options {
        display: block;
    }

    .input-group {
        display: flex;
        width: 100%;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
    }

    .input-group-text {
        padding: 0.5rem;
        background-color: #fff;
        border: none;
        display: flex;
        align-items: center;
    }

    .form-control {
        flex-grow: 1;
        border: none;
        margin: 0;
        width: 100%;
        box-shadow: none;
    }

    .options {
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        width: 100%;
        max-width: 100%;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 10;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .options .option {
        display: flex;
        padding: 10px;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;
    }

    .options .option:last-child {
        border-bottom: none; /* Elimina la línea en el último elemento */
    }

    .options .option:hover {
        background-color: #f2f2f2;
    }

    .option i{
        color: #c7c7c7;
    }

    .option .option-text{
        font-size: 1rem;
        color: #333;
        white-space: nowrap;       /* Evita que el texto se divida en varias líneas */
        overflow: hidden;          /* Oculta cualquier contenido que se desborde */
        text-overflow: ellipsis;
        text-align: center;
    }

    #options-provincia::-webkit-scrollbar{
        width: 5px;
    }

    #options-provincia::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, #c7c7c7 0%, #c7c7c7 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
        border-radius: 100px;
    }
    
    .leaflet-control-attribution {
        display: none !important;
    }

    .custom-scroll {
        padding-top: 10px;
    }

    .custom-scroll::-webkit-scrollbar {
        width: 7px;
    }

    .custom-scroll::-webkit-scrollbar-thumb {
        background-color: #f47600ad;
        border-radius: 10px;
    }

    @media (max-width: 959px) {
        .form-control {
            padding: 0.4rem; /* Reduce el espacio interno en dispositivos más pequeños */
        }

        .options {
            max-height: 200px; /* Limita la altura del menú desplegable */
        }
    }
</style>