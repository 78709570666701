<template>
  <div v-if="showModal" class="modal fade show d-block z-3 d-flex align-items-center justify-content-center" tabindex="-1">
    <div @click="closeModal()" class="h-100 w-100 position-fixed z-1 modal-overlay"></div>
    <div class="modal-dialog z-2 m-4 d-flex align-items-center justify-content-center">
      <div class="modal-content p-2 position-relative">
        <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
          <h3 class="modal-title">Editar Categoría</h3>
        </div>
        <div class="modal-body">
          <form @submit.prevent="checkCategoryState">
            <div class="row d-flex align-items-center justify-content-center">
              <span id="span-error" class="text-danger text-center pb-3"></span>
              <div class="col-md-8 mb-4">
                <div class="form-group">
                  <b for="category-description">Nombre:</b><br/>
                  <input type="text" id="category-description" v-model="localCategory.name" class="form-control shadow rounded-3" maxlength="50">
                </div>
              </div>
              <div class="col-md-8 mb-4">
                <div class="form-group">
                  <b for="dish-description">Posición para el cliente:</b><br/>
                  <input type="number" step="1" v-model="localCategory.position" class="form-control shadow rounded-3"/>
                </div>
              </div>
              <div class="col-md-12 mb-2 row m-0">
                <div class="form-check form-switch mx-auto d-flex justify-content-center">
                  <input class="form-check-input fs-5 me-3" v-model="localCategory.available" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                  <h6 class="form-check-label mt-1 fw-bold" for="flexSwitchCheckDefault">Disponible</h6>
                </div>
              </div>
            </div>
            <div class="modal-footer align-items-center justify-content-center border-none">
              <button type="submit" class="btn btn-add text-white px-4 rounded-3" style="background-color: #f47700">Actualizar</button>
              <button type="button" @click="closeModal" class="btn btn-danger px-4 rounded-3">Cancelar</button>
            </div>
          </form>
        </div>

        <div v-if="showActivateModal" class="confirmation-overlay" @click="closeConfirmationModal"></div>
        <!-- MODAL PARA ACTIVAR PLATOS -->
        <div v-if="showActivateModal" class="modal-confirmation">
          <div class="modal-content p-3">
            <div class="modal-header d-flex justify-content-between align-items-center">
              <h4 class="modal-title">Confirmar acción</h4>
              <button class="btn-close" @click="closeConfirmationModal"></button>
            </div>
            <div class="modal-body">
              <p>¿Quieres activar también los platos asociados a esta categoría?</p>
            </div>
            <div class="modal-footer d-flex align-items-center justify-content-center">
              <button class="btn btn-success" @click="confirmActivate(true)">Sí, activar platos</button>
              <button class="btn text-white" style="background-color: #f47700" @click="confirmActivate(false)">No, solo activar categoría</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
      category: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        localCategory: {},
        activatePlates: false,
        showActivateModal: false,
        previousAvailable: false,
      };
    },
    mounted() {
      if (this.category) {
        this.localCategory = { ...this.category };
        this.previousAvailable = this.category.available ?? false;
      }
    },
    methods: {
      closeModal() {
        this.$emit("close-modal");
      },
      checkCategoryState() {
        if (!this.localCategory.name || !this.localCategory.position) {
          document.getElementById("span-error").textContent =
            "Debes rellenar todos los campos para continuar";
          return;
        }

        if (this.previousAvailable === false && this.localCategory.available === true) {
          this.showActivateModal = true;
        } 
        else {
          this.submitEdit();
        }
      },
      confirmActivate(choice) {
        this.activatePlates = choice;
        this.showActivateModal = false;
        this.submitEdit();
      },
      closeConfirmationModal() {
        this.showActivateModal = false;
      },
      submitEdit() {
        this.$emit("update-category", {
          ...this.localCategory,
          activatePlates: this.activatePlates || false,
        });
        this.closeModal();
        this.activatePlates = false
      },
    },
    watch: {
      category(newCategory) {
        if (newCategory) {
          this.localCategory = { ...newCategory };
          this.previousAvailable = newCategory.available ?? false;
        }
      },
    },
  };
</script>

<style scoped>
  .modal { 
    background-color: rgba(139, 139, 139, 0.5); 
  }

  .modal-dialog {
    height: auto; 
    min-width: 60%;
  }

  .modal-content {
    max-height: 90vh;
  }

  .modal-confirmation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 80%;
    max-width: 400px;
    z-index: 1050; /* Asegura que esté por encima del contenido del modal principal */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  .confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }

  @media (min-width: 767px) {
    .modal-dialog {
      position: absolute;
      width: 80%;
      left: 28%;
      min-height: auto; 
      min-width: 60%; 
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }
</style>
