import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Home.vue";
import Carta from "../views/Carta.vue";
import Cuenta from "../views/Cuenta.vue";
import Carrito from "../views/Carrito.vue";
import PanelComandas from "../views/PanelComandas.vue";
import PanelMesas from "../views/PanelMesas.vue";
import AccesoMesas from "../views/AccesoMesas.vue";
import Login from "../views/Login.vue";
import ChangePass from "../views/ChangePass.vue";
import MisPedidos from "../views/MisPedidos.vue";
import LoginQr from "../views/LoginQr.vue";
import LoginDelivery from "../views/LoginDelivery.vue";
import LoginTakeAway from "../views/LoginTakeAway.vue";
import Unauthorized from "../views/Unauthorized.vue";
import Landing from "../views/Landing.vue";
import PartnerSettings from "../views/PartnerSettings.vue";
import GestionMesas from "../views/GestionMesas.vue";
import GestionCamareros from "../views/GestionCamareros.vue";
import GestionCarta from "../views/GestionCarta.vue";
import DashboardPartner from "../views/DashboardPartner.vue";
import LoginAdmin from "../views/LoginAdmin.vue";
import MandarIncidencias from "../views/MandarIncidencias.vue";
import PoliticaPrivacidad from "../views/PrivacyPolicy.vue";
//import store from '../store';


const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: { showFooter: false, authorized: false, fullpage:true },
  },
  {
    path: "/PoliticaPrivacidad",
    name: "PoliticaPrivacidad",
    component: PoliticaPrivacidad,
    meta: { showFooter: false, authorized: false, fullpage:true },
  },
  {
    path: "/PartnerSettings",
    name: "PartnerSettings",
    component: PartnerSettings,
    meta: { showFooter: false, authorized: false, partnerMenu: true },
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    meta: { showFooter: true, authorized: true },
  },
  {
    path: "/Carta/:restaurant",
    name: "Carta",
    component: Carta,
    meta: { showFooter: false, authorized: false, fullpage:true },
  },
  {
    path: "/Cuenta",
    name: "Cuenta",
    component: Cuenta,
    meta: { showFooter: true, authorized: true  },
  },
  {
    path: "/Carrito",
    name: "Carrito",
    component: Carrito,
    meta: { showFooter: true, authorized: true  },
  },
  {
    path: "/MisPedidos",
    name: "MisPedidos",
    component: MisPedidos,
    meta: { showFooter: true, authorized: true  },
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
    meta: { showFooter: false, authorized: false, fullpage:true  },
  },
  {
    path: "/ChangePass/:token?",
    name: "ChangePass",
    component: ChangePass,
    meta: { showFooter: false, authorized: false  },
  },
  {
    path: "/LoginQr/:mesa",
    name: "LoginQr",
    component: LoginQr,
    meta: { showFooter: false, authorized: false, fullpage:true  },
  },
  {
    path: "/Delivery/:delivery_name",
    name: "LoginDelivery",
    component: LoginDelivery,
    meta: { showFooter: false, authorized: false, fullpage:true  },
  },
  {
    path: "/TakeAway/:delivery_name",
    name: "LoginTakeAway",
    component: LoginTakeAway,
    meta: { showFooter: false, authorized: false, fullpage:true  },
  },
  {
    path: "/Unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    meta: { partnerMenu: false, showFooter: false, authorized: false, fullpage:true  },
  },
  {
    path: "/PanelComandas",
    name: "PanelComandas",
    component: PanelComandas,
    meta: {showFooterWaiter: true, authorized: false}
  },
  {
    path: "/PanelMesas",
    name: "PanelMesas",
    component: PanelMesas,
    meta: {showFooterWaiter: true, authorized: false}
  },
  {
    path: "/AccesoMesas",
    name: "AccesoMesas",
    component: AccesoMesas,
    meta: {showFooterWaiter: true, authorized: false}
  },
  {
    path: "/GestionMesas",
    name: "GestionMesas",
    component: GestionMesas,
    meta: { partnerMenu: true, authorized: false  },
  },
  {
    path: "/GestionCamareros",
    name: "GestionCamareros",
    component: GestionCamareros,
    meta: { partnerMenu: true, authorized: false  },
  },
  {
    path: "/GestionCarta",
    name: "GestionCarta",
    component: GestionCarta,
    meta: { partnerMenu: true, authorized: false  },
  },
  {
    path: "/DashboardPartner",
    name: "DashboardPartner",
    component: DashboardPartner,
    meta: { partnerMenu: true, authorized: false  },
  },
  {
    path: "/LoginAdmin",
    name: "LoginAdmin",
    component: LoginAdmin,
    meta: { showFooter: false, authorized: false  },
  },
  {
    path: "/MandarIncidencias",
    name: "MandarIncidencias",
    component: MandarIncidencias,
    meta: { partnerMenu: true, authorized: false  },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  //await store.dispatch('initialice');
  window.scrollTo(0, 0);
  next();
});

// eslint-disable-next-line no-redeclare
/* global fbq */

router.afterEach(() => {
  if (window.fbq) {
    fbq('track', 'PageView');
  }
});

export default router;